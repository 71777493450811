/* eslint-disable  */
import { Grid, Typography } from '@rentacenter/racstrap';
import { VerificationStyles } from '../../../styles/verificationStyles';
import doflogo from '../../../images/logo.svg';
import { ReactComponent as TickIcon } from './../../../images/tick-icon.svg';
import { useEffect } from 'react';

export const DOFSubmitted = () => {
  const classes: any = VerificationStyles();
  let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
  let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  const clientId: any = sessionStorageValues?.clientId;


  // Adding the Google Analytics tags

  useEffect(() => {
    window.dataLayer?.push({
      'event': 'Loaded the DOF Submission Page',
      'storeNo': sessionStorageValues?.storeNumber,
      'approvalId': sessionStorageValues?.approvalId,
      'currentScreen': 'DOF Submission Screen',
      'previousScreen': 'DOF Review Information screen',
    });
  }, [])

  return (
    <Grid style={{ width: "100%", height: "80vh" }}>
      <Grid style={{ marginTop: "7%" }}>
        {(atob(clientId) == "EC" || atob(clientId) == 'ECOM') ? null : (
          <Grid style={{ textAlign: "center", display: "inline-flex" }}>
            <img
              src={doflogo}
              style={{
                float: "left",
                verticalAlign: "middle",
                padding: "16px 24px",
              }}
            />
          </Grid>
        )}



        <Typography
          className={`${classes.primaryFontColor} ${classes.responsiveFontTitle} ${classes.boldFont1} ${classes.p10px}`}
        >
          Thank You
        </Typography>

        <Grid>
          <Typography
            className={`${classes.responsiveFont14px} ${classes.p10px} ${classes.responsiveText}`}
          >
            Your verification documents have been successfully submitted. A
            store coworker will reach out to you to finish your order and
            schedule your delivery.
          </Typography>
        </Grid>

        <Grid className={`${classes.headingWidth} ${classes.mt30px}`}>
          <TickIcon />
        </Grid>

        {/* <RACButton
          color="primary"
          variant="contained"
          className={`${classes.primaryButtonColor} ${classes.buttonsPadding} ${classes.mt30px}`}
          onClick={() => {
            window.parent.postMessage(
              {
                status: 200,
                message: "DOF Verification completed successfully",
              },
              "*"
            );
          }}
        >
          Close
        </RACButton> */}
      </Grid>
    </Grid>
  );
};

/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
export const ResidenceTabContext = createContext<any>('');
import { dropdown } from '../Common/HelperFunctions';

export function ResidenceTabContextProvider(props: any) {
    const [landlordResidence, setLandlordResidence] = useState({
        landLordName: '',
        timeAtCurrentAddress: '',
        landLordPhoneNumber: '',
        formatedMobileNumber: '',
        landlordReferenceId: ''

    })
    const [landlordResidenceCopy, setLandlordResidenceCopy] = useState({
        landLordName: '',
        timeAtCurrentAddress: '',
        landLordPhoneNumber: '',
        residenceDocCopy: ''
    })
    const [residenceType, setResidenceType] = useState<string>('RENT');
    const [manualEntryEnabled, setManualEntryEnabled] = useState(false);
    const [fileSize, setFileSize] = useState<string>('');
    const [fileType, setFileType] = useState<string>('');
    const [residenceTenureTypeOptions, setResidenceTenureTypeOptions] = useState<dropdown[]>([]);
    const [resFileSizeCopy, setResFileSizeCopy] = useState<string>('')
    const [resFileTypeCopy, setResFileTypeCopy] = useState<string>("")

    return (
        <div>
            <ResidenceTabContext.Provider
                value={{
                    landlordResidence, setLandlordResidence,
                    manualEntryEnabled, setManualEntryEnabled,
                    residenceType, setResidenceType,
                    fileType, setFileType,
                    fileSize, setFileSize,
                    residenceTenureTypeOptions, setResidenceTenureTypeOptions,
                    landlordResidenceCopy, setLandlordResidenceCopy,
                    resFileSizeCopy, setResFileSizeCopy,
                    resFileTypeCopy, setResFileTypeCopy
                }}
            >
                {props.children}
            </ResidenceTabContext.Provider>
        </div>
    );
}

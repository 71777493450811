/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-duplicate-string */

import {
  GetCustomerDetails,
  getIntellicheckVerificationDetails,
  sendIntellicheckVerificationLink,
  UpdateCustomerInfo,
} from "../../../api/user";
import {
  BUSINESS_UNIT,
  CUSTOMER_INFO_TEMPLATE,
  EMPTY_STRING,
  INTELLICHECK_DETAILS_TEMPLATE,
} from "../../../constants/constants";
import {
  Address,
  CommunicationConsent,
  CustomerInfo,
  CustomerRoute,
  DecryptDataPayload,
  DecryptedData,
  GetIntellicheckDetailsPayload,
  IntellicheckCustomerDetails,
  Phone,
} from "../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel";
import { getArrayValue, getValue, updateCustomerApproval, uploadDocuments } from "./HelperFunctions";
import crypto, { createDecipheriv } from "crypto";

export const getCustomerDetails = async (
  customerId: string,
  setCVOCustomerDetails: React.Dispatch<React.SetStateAction<CustomerInfo>>,
  landlordResidence?: any,
  setLandlordResidence?: any,
  familyReferenceDetails?: any,
  setFamilyReferenceDetails?: any,
  nonFamilyReferenceDetails?: any,
  setnonFamilyReferenceDetails?: any,
  employementDetails?: any,
  setEmployementDetails?: any

): Promise<CustomerInfo> => {
  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;
  const getCustomerRequest = {
    getCustomerDetails: true,
    customerId,
  };

  let customerData: any = await GetCustomerDetails(
    getCustomerRequest,
    isStoreCodeFlow,
    accessToken
  );

  console.log("getCustomerDetails Response", customerData);

  if (customerData?.status === 200 && customerData?.data) {
    const decryptDataPayload: DecryptDataPayload = {
      decryptData: {},
      encryptKey: crypto.randomBytes(16).toString("hex"),
      ivKey: crypto.randomBytes(8).toString("hex"),
    };

    if (customerData?.data?.dateOfBirthEnc)
      decryptDataPayload.decryptData.dateOfBirth =
        customerData.data.dateOfBirthEnc;
    if (customerData?.data?.taxIdEnc)
      decryptDataPayload.decryptData.taxIdEnc = customerData.data.taxIdEnc;
    if (customerData?.data?.governmentId)
      decryptDataPayload.decryptData.governmentIdEnc =
        customerData.data.governmentIdEnc;

    const decryptDataResponse = await GetCustomerDetails(
      decryptDataPayload,
      isStoreCodeFlow,
      accessToken
    );
    console.log("decryptResponse", decryptDataResponse);

    const decryptedData: DecryptedData = await handleDecryption(
      decryptDataPayload,
      decryptDataResponse
    );
    customerData = customerData?.data;
    const customerInfo: CustomerInfo = await mapCustomerDataToInfo(
      customerData,
      decryptedData,
      landlordResidence,
      setLandlordResidence,
      familyReferenceDetails,
      setFamilyReferenceDetails,
      nonFamilyReferenceDetails,
      setnonFamilyReferenceDetails,
      employementDetails,
      setEmployementDetails);

    console.log("getCustomerDetails", customerInfo);
    setCVOCustomerDetails(customerInfo);
    return customerInfo;
  } else {
    console.log("Customer data retrieval failed");
    setCVOCustomerDetails(CUSTOMER_INFO_TEMPLATE);
    return CUSTOMER_INFO_TEMPLATE;
  }
};

const decryptCustomerData = async (
  encryptKey: string,
  ivKey: string,
  encryptedData: any
) => {
  if (encryptedData) {
    return getData(encryptKey, ivKey, encryptedData);
  }
  return EMPTY_STRING;
};

const handleDecryption = async (
  decryptDataPayload: DecryptDataPayload,
  decryptDataResponse: any
): Promise<DecryptedData> => {
  if (
    decryptDataResponse?.status == 200 &&
    decryptDataResponse?.data?.decryptData
  ) {
    return {
      dateOfBirth: await decryptCustomerData(
        decryptDataPayload.encryptKey,
        decryptDataPayload.ivKey,
        decryptDataResponse?.data?.decryptData?.dateOfBirth
      ),
      taxId: await decryptCustomerData(
        decryptDataPayload.encryptKey,
        decryptDataPayload.ivKey,
        decryptDataResponse?.data?.decryptData?.taxIdEnc
      ),
      governmentId: await decryptCustomerData(
        decryptDataPayload.encryptKey,
        decryptDataPayload.ivKey,
        decryptDataResponse?.data?.decryptData?.governmentIdEnc
      ),
    };
  } else {
    return {
      dateOfBirth: EMPTY_STRING,
      taxId: EMPTY_STRING,
      governmentId: EMPTY_STRING,
    };
  }
};
export const formatMobileNumber = (value: string | undefined): any => {
  if (value) {
    const phoneFormat = "($1) $2-$3";
    const cleaned = ("" + value).replace(/\D/g, "");
    //This is raw data to pass on props
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, phoneFormat);
  }
  return undefined

};

const filterReferences = (personalReferences: any) => {
  const familyReferenceRefcode = ['SPO', 'SIBLING', 'PARENT', 'CHILD'];
  const nonFamilyReferenceRefcode = ['FRI', 'CWR', 'LND', 'OTH'];

  const familyReferences = personalReferences.filter((item: any) => familyReferenceRefcode.includes(item.relationshipType));
  const nonFamilyReferences = personalReferences.filter((item: any) => nonFamilyReferenceRefcode.includes(item.relationshipType));

  return {
    familyReferenceReference: familyReferences,
    nonFamilyReferenceReference: nonFamilyReferences
  };
};
const mapCustomerDataToInfo = (
  customerData: any,
  decryptedData: DecryptedData,
  landlordResidence?: any,
  setLandlordResidence?: any,
  familyReferenceDetails?: any,
  setFamilyReferenceDetails?: any,
  nonFamilyReferenceDetails?: any,
  setnonFamilyReferenceDetails?: any,
  employementDetails?: any,
  setEmployementDetails?: any
): CustomerInfo => {
  console.log("Customer data inside mapper", customerData);
  const EMPTY_STRING: string = "";
  const landlordReferences = customerData?.landlordReferences?.[0] || null;
  const PersonalReferences = customerData?.personalReferences || null;
  const employerReference = customerData?.employerReferences?.[0] || null;



  if (landlordReferences) {
    setLandlordResidence({ ...landlordResidence, landLordName: landlordReferences?.landlordFirstName || EMPTY_STRING, timeAtCurrentAddress: landlordReferences?.residenceTenureType || EMPTY_STRING, landLordPhoneNumber: landlordReferences?.phoneNumber || EMPTY_STRING, landlordReferenceId: landlordReferences?.landlordReferenceId || EMPTY_STRING, formatedMobileNumber: formatMobileNumber(landlordReferences?.phoneNumber) || EMPTY_STRING })
  }
  if (employerReference) {
    setEmployementDetails({ ...employementDetails, employerName: employerReference?.employerName || EMPTY_STRING, employerPhoneNumber: employerReference?.employerPhoneNumber || EMPTY_STRING, employerReferenceId: employerReference?.employerReferenceId || EMPTY_STRING, formatedMobileNumber: formatMobileNumber(employerReference?.employerPhoneNumber) || EMPTY_STRING })
  }

  if (PersonalReferences) {
    const referenceRes: any = filterReferences(PersonalReferences)

    if (referenceRes?.familyReferenceReference && referenceRes?.familyReferenceReference?.length) {

      const familyRes: any = referenceRes?.familyReferenceReference
      setFamilyReferenceDetails({ ...familyReferenceDetails, referenceName: familyRes[0]?.firstName || EMPTY_STRING, relationShipType: familyRes[0]?.relationshipType || EMPTY_STRING, phoneNumber: familyRes[0]?.phoneNumber || EMPTY_STRING, personalReferenceId: familyRes[0]?.personalReferenceId || EMPTY_STRING, formatedMobileNumber: formatMobileNumber(familyRes[0]?.phoneNumber) || EMPTY_STRING })
    }
    if (referenceRes?.familyReferenceReference?.length && referenceRes?.nonFamilyReferenceReference && referenceRes?.nonFamilyReferenceReference?.length == 1) {

      const nonFamilRefRes: any = referenceRes?.nonFamilyReferenceReference
      setnonFamilyReferenceDetails({ ...nonFamilyReferenceDetails, referenceName: nonFamilRefRes[0]?.firstName || EMPTY_STRING, relationShipType: nonFamilRefRes[0]?.relationshipType || EMPTY_STRING, phoneNumber: nonFamilRefRes[0]?.phoneNumber || EMPTY_STRING, personalReferenceId: nonFamilRefRes[0]?.personalReferenceId || EMPTY_STRING, formatedMobileNumber: formatMobileNumber(nonFamilRefRes[0]?.phoneNumber) || EMPTY_STRING })
    }
    if (referenceRes?.familyReferenceReference?.length == 0 && referenceRes?.nonFamilyReferenceReference?.length > 1) {

      const nonFamilRefRes: any = referenceRes?.nonFamilyReferenceReference
      setFamilyReferenceDetails({ ...familyReferenceDetails, referenceName: nonFamilRefRes[0]?.firstName || EMPTY_STRING, relationShipType: nonFamilRefRes[0]?.relationshipType || EMPTY_STRING, phoneNumber: nonFamilRefRes[0]?.phoneNumber || EMPTY_STRING, personalReferenceId: nonFamilRefRes[0]?.personalReferenceId || EMPTY_STRING, formatedMobileNumber: formatMobileNumber(nonFamilRefRes[0]?.phoneNumber) || EMPTY_STRING })
      setnonFamilyReferenceDetails({ ...nonFamilyReferenceDetails, referenceName: nonFamilRefRes[1]?.firstName || EMPTY_STRING, relationShipType: nonFamilRefRes[1]?.relationshipType || EMPTY_STRING, phoneNumber: nonFamilRefRes[1]?.phoneNumber || EMPTY_STRING, personalReferenceId: nonFamilRefRes[1]?.personalReferenceId || EMPTY_STRING, formatedMobileNumber: formatMobileNumber(nonFamilRefRes[1]?.phoneNumber) || EMPTY_STRING })

    }

    if (referenceRes?.familyReferenceReference?.length == 0 && referenceRes?.nonFamilyReferenceReference && referenceRes?.nonFamilyReferenceReference?.length == 1) {

      const nonFamilRefRes: any = referenceRes?.nonFamilyReferenceReference
      setFamilyReferenceDetails({ ...familyReferenceDetails, referenceName: nonFamilRefRes[0]?.firstName || EMPTY_STRING, relationShipType: nonFamilRefRes[0]?.relationshipType || EMPTY_STRING, phoneNumber: nonFamilRefRes[0]?.phoneNumber || EMPTY_STRING, personalReferenceId: nonFamilRefRes[0]?.personalReferenceId || EMPTY_STRING, formatedMobileNumber: formatMobileNumber(nonFamilRefRes[0]?.phoneNumber) || EMPTY_STRING })
    }
  }

  return {
    personalInfo: {
      customerId: getValue(customerData, "customerId"),
      globalCustomerId: getValue(customerData, "globalCustomerId"),
      firstName: getValue(customerData, "firstName"),
      lastName: getValue(customerData, "lastName"),
      middleInitial: getValue(customerData, "middleInitial"),
      dateOfBirth: getValue(customerData, "dateOfBirth"),
      dateOfBirthEnc: getValue(customerData, "dateOfBirthEnc"),
      dateOfBirthDec: decryptedData.dateOfBirth,
      emailAddress: getValue(customerData, "emailAddress"),
      preferredContactMethod: getValue(customerData, "preferredContactMethod"),
      preferredLanguage: getValue(customerData, "preferredLanguage"),
      salutation: getValue(customerData, "salutation"),
      salutationDesc: getValue(customerData, "salutationDesc"),
      suffix: getValue(customerData, "suffix"),
      suffixDesc: getValue(customerData, "suffixDesc"),
      salesLeadId: getValue(customerData, "salesLeadId"),
      createdBy: getValue(customerData, "createdBy")
    },
    taxInfo: {
      taxId: getValue(customerData, "taxId"),
      taxIdEnc: getValue(customerData, "taxIdEnc"),
      taxIdDec: decryptedData.taxId,
      taxIdType: getValue(customerData, "taxIdType"),
      governmentId: getValue(customerData, "governmentId"),
      governmentIdEnc: getValue(customerData, "governmentIdEnc"),
      governmentIdDec: decryptedData.governmentId,
      governmentIdType: getValue(customerData, "governmentIdType"),
      governmentIdTypeDesc: getValue(customerData, "governmentIdTypeDesc"),
      governmentIdIssuedState: getValue(
        customerData,
        "governmentIdIssuedState"
      ),
    },
    solicitationPreferences: {
      phoneSolicitationAllowed: getValue(
        customerData,
        "phoneSolicitationAllowed"
      ),
      emailSolicitationAllowed: getValue(
        customerData,
        "emailSolicitationAllowed"
      ),
      mailSolicitationAllowed: getValue(
        customerData,
        "mailSolicitationAllowed"
      ),
      textSolicitationAllowed: getValue(
        customerData,
        "textSolicitationAllowed"
      ),
      paymentReminderCallsAllowed: getValue(
        customerData,
        "paymentReminderCallsAllowed"
      ),
      isNDNCOpted: getValue(customerData, "isNDNCOpted"),
      isTrnsMsgAllowed: getValue(customerData, "isTrnsMsgAllowed"),
      isMktgMsgAllowed: getValue(customerData, "isMktgMsgAllowed"),
    },
    paymentPreferences: {
      acceptCheck: getValue(customerData, "acceptCheck"),
      remotePaymentAllowed: getValue(customerData, "remotePaymentAllowed"),
      achAllowed: getValue(customerData, "achAllowed"),
    },
    agreementInfo: {
      activeAgreements: getValue(customerData, "activeAgreements"),
      inActiveAgreements: getValue(customerData, "inActiveAgreements"),
      pifAgreements: getValue(customerData, "pifAgreements"),
      epoAgreements: getValue(customerData, "epoAgreements"),
      coCustomer: getValue(customerData, "coCustomer"),
      coCustomerId: getValue(customerData, "coCustomerId"),
    },
    residenceInfo: {
      residenceSince: getValue(customerData, "residenceInfo.residenceSince"),
      residenceType: getValue(customerData, "residenceInfo.residenceType"),
    },
    legalInfo: {
      legalHold: getValue(customerData, "legalHold"),
      verified: getValue(customerData, "verified"),
      verifiedDate: getValue(customerData, "verifiedDate"),
    },
    customerRoutes: getArrayValue(customerData, "customerRoutes").map(
      (route: CustomerRoute) => ({
        customerRouteId: getValue(route, "customerRouteId"),
        storeRouteId: getValue(route, "storeRouteId"),
        storeNumber: getValue(route, "storeNumber"),
        routeCodeDesc: getValue(route, "routeCodeDesc"),
      })
    ),
    addresses: getArrayValue(customerData, "addresses").map(
      (address: Address) => ({
        addressId: getValue(address, "addressId"),
        addressType: getValue(address, "addressType"),
        addressTypeDesc: getValue(address, "addressTypeDesc"),
        addressLine1: getValue(address, "addressLine1"),
        addressLine2: getValue(address, "addressLine2"),
        city: getValue(address, "city"),
        state: getValue(address, "state"),
        postalCode: getValue(address, "postalCode"),
        latitude: getValue(address, "latitude"),
        longitude: getValue(address, "longitude"),
        active: getValue(address, "active"),
        verifiedDate: getValue(address, "verifiedDate"),
      })
    ),
    phones: getArrayValue(customerData, "phones").map((phone: Phone) => ({
      phoneId: getValue(phone, "phoneId"),
      phoneNumber: getValue(phone, "phoneNumber"),
      phoneType: getValue(phone, "phoneType"),
      phoneTypeDesc: getValue(phone, "phoneTypeDesc"),
      primary: getValue(phone, "primary"),
      callTimeType: getValue(phone, "callTimeType"),
      callTimeTypeDesc: getValue(phone, "callTimeTypeDesc"),
      active: getValue(phone, "active"),
      isNDNCOpted: getValue(phone, "isNDNCOpted"),
      isTrnsMsgAllowed: getValue(phone, "isTrnsMsgAllowed"),
      isMktgMsgAllowed: getValue(phone, "isMktgMsgAllowed"),
    })),
    consentInfo: getArrayValue(customerData, "communicationConsent").map(
      (consent: CommunicationConsent) => ({
        phoneNumber: getValue(consent, "phoneNumber"),
        consentType: getValue(consent, "consentType"),
        consent: getValue(consent, "consent"),
        lineOfBusiness: getValue(consent, "lineOfBusiness"),
        consentDate: getValue(consent, "consentDate"),
      })
    ),
    customerStatus: {
      customerSinceDate: getValue(customerData, "customerSinceDate"),
      createdBy: getValue(customerData, "createdBy"),
      lastModifiedBy: getValue(customerData, "lastModifiedBy"),
      createdDate: getValue(customerData, "createdDate"),
      lastModifiedDate: getValue(customerData, "lastModifiedDate"),
      createdSource: getValue(customerData, "createdSource"),
      lastModifiedSource: getValue(customerData, "lastModifiedSource"),
    },
  };
};

export const getIntellicheckDetails = async (
  approvalId: string,
  setIntellicheckVerfDetails: React.Dispatch<
    React.SetStateAction<IntellicheckCustomerDetails>
  >,
  customerId: string,
  intellicheckPassScore: any,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;
  const getIntellicheckDetailsPayload: GetIntellicheckDetailsPayload = {
    customerApprovalId: `cust-${approvalId}`,
    businessUnit: BUSINESS_UNIT,
  };

  const getIntellicheckStatusResp: any =
    await getIntellicheckVerificationDetails(getIntellicheckDetailsPayload, isStoreCodeFlow, accessToken);
  // eslint-disable-next-line no-console
  console.log(
    "Response from getIntellicheckService",
    getIntellicheckStatusResp
  );
  if (getIntellicheckStatusResp?.status === 200) {
    const { data } = getIntellicheckStatusResp.data ?? {};

    if (data.status == "incomplete") {
      return "incomplete";
    } else {
      // eslint-disable-next-line no-console
      // console.log("Response Data", data);
      // Destructure data with default values applied
      const {
        first_name = EMPTY_STRING,
        last_name = EMPTY_STRING,
        dob = EMPTY_STRING,
        address1 = EMPTY_STRING,
        address2 = EMPTY_STRING,
        locality = EMPTY_STRING,
        province_code = EMPTY_STRING,
        postal_code = EMPTY_STRING,
        country_code = EMPTY_STRING,
        name_score = 0,
        location_score = null,
        id_score = 0,
        id_document = {},
        matches = {},
        images = {},
      } = data ?? {};

      // Destructure nested properties with default values
      const {
        number = EMPTY_STRING,
        type = EMPTY_STRING,
        province_code: idDocProvinceCode = EMPTY_STRING,
      } = id_document;
      const {
        first_name: matchFirstName = false,
        last_name: matchLastName = false,
        dob: matchDob = false,
      } = matches;
      const { front = EMPTY_STRING, selfie = EMPTY_STRING } = images;

      const getIntellicheckDetails: IntellicheckCustomerDetails = {
        first_name,
        last_name,
        dob,
        address1,
        address2,
        locality,
        province_code,
        postal_code,
        country_code,
        name_score,
        location_score,
        id_score,
        id_document: {
          number,
          type,
          province_code: idDocProvinceCode,
        },
        matches: {
          first_name: matchFirstName,
          last_name: matchLastName,
          dob: matchDob,
        },
        images: {
          front,
          selfie,
        },
      };

      // eslint-disable-next-line no-console
      console.log("Intellicheck Details", getIntellicheckDetails);
      setIntellicheckVerfDetails(getIntellicheckDetails);
      const decryptDataPayload: DecryptDataPayload = {
        decryptData: {},
        encryptKey: crypto.randomBytes(16).toString("hex"),
        ivKey: crypto.randomBytes(8).toString("hex"),
      };

      decryptDataPayload.decryptData.governmentIdEnc = id_document.number;
      decryptDataPayload.decryptData.dateOfBirth = dob;

      const decryptDataResponse = await GetCustomerDetails(
        decryptDataPayload,
        isStoreCodeFlow,
        accessToken
      );

      console.log("decryptResponse", decryptDataResponse);

      const decryptedData: DecryptedData = await handleDecryption(
        decryptDataPayload,
        decryptDataResponse
      );


      const payload = {
        customerId: customerId,
        governmentIdType: id_document.type ? id_document.type == 'na_dl' ? 'DL' : id_document.type == 'pass' ? 'UPASS' : 'OTHER' : 'OTHER',
        governmentId: decryptedData.governmentId,
        governmentIdIssuedState: id_document?.province_code,
      };
      updateIntellicheckDetails(getIntellicheckDetails, approvalId, 'Intellicheck', intellicheckPassScore, decryptedData.dateOfBirth);
      UpdateCustomerInfo(payload, isStoreCodeFlow, accessToken);

      const updateDetails: any = {
        status: id_score >= intellicheckPassScore && Object.values(matches).every(value => value === true) ? 'VERIFIED' : 'PENDREW',
        modifiedBy: id_score >= intellicheckPassScore && Object.values(matches).every(value => value === true) ? 'Intellicheck' : 'DOF'
      }
      updateCustomerApproval("ID", approvalId, customerId, updateDetails);
      const uploadPayload: any = [
        {
          documentType: "IDENTTYDOC",
          file: front,
        }
      ];

      if (selfie) {
        uploadPayload.push({
          documentType: "SELFIEIMG",
          file: selfie,
        })
      }
      uploadDocuments(approvalId, uploadPayload);

      return getIntellicheckDetails;
    }
  } else {
    setIntellicheckVerfDetails(INTELLICHECK_DETAILS_TEMPLATE);
    return INTELLICHECK_DETAILS_TEMPLATE;
  }
};

const getData = (key: string, iv: string, data: string) => {
  // eslint-disable-next-line no-console
  console.log("payloadKeys-->", key, iv, data);
  const decipher = createDecipheriv("aes-256-cbc", key, iv);
  let result: any = decipher.update(data, "hex", "utf8");
  result += decipher.final("utf8");
  // eslint-disable-next-line no-console
  console.log("payloadKeys", iv, key, data, result);
  return result;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const updateIntellicheckDetails = async (response: any, approvalId: any, modifiedBy: any, passLimit: number, dateOfBirth: any) => {
  console.log('INside the updateIntellicheckdetails', response, approvalId, modifiedBy, passLimit)
  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;

  const verificationStatus = response?.id_score ? response?.id_score >= passLimit ? 'VERIFIED' : 'PENDREW' : 'PENDREW'
  const updateIntellicheckReq: any = {
    approvalVerifications: [
      {
        verificationStatus: verificationStatus,
        verificationEvidence: {
          lastName: response?.last_name,
          locationScore: response?.location_score
            ? response?.location_score?.toString()
            : "0",
          idScore: response?.id_score ? response?.id_score?.toString() : "0",
          address1: response?.address1 || "",
          provinceCode: response?.province_code || "",
          postalCode: response?.postal_code || "",
          idDocument: {
            number: response?.id_document?.number || "",
            provinceCode: response?.id_document?.province_code || "",
            type: response?.id_document?.type || "",
          },
          locality: response?.locality,
          matches: {
            firstName: response?.matches?.first_name ? true : false,
            lastName: response?.matches?.last_name ? true : false,
            dob: response?.matches?.dob ? true : false,
          },
          firstName: response?.first_name || "",
          dob: dateOfBirth,
          countryCode: response?.country_code,
          nameScore: response?.name_score ? response?.name_score?.toString() : "0",
        },
        modifiedBy: verificationStatus == 'VERIFIED' ? modifiedBy : 'DOF', // This would typically be dynamic, replace if needed
        verifyType: "ID",
      },
    ],
  };

  const reqPayload = {
    isUpdateVerificationDocuments: true,
    globalCustomerId: approvalId,
    approvalVerifications: updateIntellicheckReq.approvalVerifications
  }

  try {
    const updateDetailsResp: any = await sendIntellicheckVerificationLink(reqPayload, isStoreCodeFlow, accessToken);
    console.log('updated details', updateDetailsResp);
    if (updateDetailsResp?.status == 200) {
      console.log('Updated Details', updateDetailsResp)
    } else {
      console.log('Error in Updated Details', updateDetailsResp)
    }
  } catch (e: any) {
    console.log('Error in SendIntellicheckVerificationLink', e);
  }
} 
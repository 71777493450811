/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, RACButton, List } from "@rentacenter/racstrap";
import doflogo from "../../../images/logo.svg";
import { VerificationStyles } from "../../../styles/verificationStyles";
import { useHistory, useParams } from "react-router-dom";
import { DigitalOrderFormContext } from "../Context/DigitialOrderFormContext";
import { getCustomerDetails } from "../Common/GetCustomerDetails";
import ScreenLoader from "../Common/ScreenLoader";
import { getCustomerId } from "../Common/HelperFunctions";
import { ResidenceTabContext } from "../Context/ResidenceDetailsContext";
import { ReferenceTabContext } from "../Context/ReferenceDetailsContext";
import { generateDOFAccessLink } from "../../../api/user";
import { IncomeTabContext } from "../Context/IncomeDetailsContext";

const DOFStartingPage: React.FC = () => {
  const classes: any = VerificationStyles();
  let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
  let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  const clientId: any = sessionStorageValues?.clientId
  const {
    setCVOCustomerDetails,
    customerId,
    decisionEngineDetails,
    setCustomerId,
    setRacPadCustomerDetail,
    getApprovalServiceResponse,
    setDecisionEngineDetails,
    custMobNumber
  } = useContext(DigitalOrderFormContext);
  const { employementDetails, setEmployementDetails } = useContext(IncomeTabContext)

  const { landlordResidence,
    setLandlordResidence } = useContext(ResidenceTabContext)

  const { familyReferenceDetails,
    setFamilyReferenceDetails,
    nonFamilyReferenceDetails,
    setnonFamilyReferenceDetails } = useContext(ReferenceTabContext)
  const history = useHistory();
  const { approvalId } = useParams<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [customer, setCustomer] = useState<string>("");

  const handleStartBtn = async () => {
    history.push(`/identity/${customerId}/${approvalId}`);
  };

  // Adding the Google Analytics tags

  useEffect(() => {
    window.dataLayer?.push({
      'event': 'Loaded the DOF Starting Page',
      'storeNo': sessionStorageValues?.storeNumber,
      'approvalId': sessionStorageValues?.approvalId,
      'currentScreen': 'DOF Starting Screen',
      'previousScreen': 'OTP Verified Screen',
    });
  }, [])

  useEffect(() => {

    const fetchCustomerDetails = async () => {
      setLoader(true);
      console.log(
        "customerId11",
        customerId,
        decisionEngineDetails?.globalCustomerId
      );
      const dofAuthSessionValue: any =
        window.sessionStorage.getItem("dofAuthValues");
      const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
      console.log("Session Storage details", sessionStorageValues);
      const isStoreCodeFlow: any = sessionStorageValues?.authKey
        ? false
        : sessionStorageValues?.storeNumber;
      const accessToken: any = sessionStorageValues?.authKey
        ? sessionStorageValues?.authKey
        : sessionStorageValues?.storeNumber;
      if (isStoreCodeFlow) {
        const generateLinkPayload: any = {
          source: atob(sessionStorageValues?.clientId),
          userId: sessionStorageValues?.encodeUserId,
          storeNumber: sessionStorageValues?.storeNumber,
          approvalId: sessionStorageValues?.approvalId,
          phoneNumber: custMobNumber,
          sendLinkToCustomer: false,
        };

        const generateLinkResp = await generateDOFAccessLink(
          generateLinkPayload,
          isStoreCodeFlow,
          accessToken
        );

        console.log("GenerateLinkResp", generateLinkResp);
        if (
          generateLinkResp?.status == 200 &&
          generateLinkResp?.data?.digitalOrderFormURL
        ) {
          const url = generateLinkResp?.data?.digitalOrderFormURL;
          const urlParams = new URLSearchParams(new URL(url).search);
          const authKey = urlParams.get("AuthKey");
          const dofAuthSessionValuesSession: any =
            window.sessionStorage.getItem("dofAuthValues");
          console.log('AuthKey details', dofAuthSessionValuesSession, authKey);
          const dofAuthValues: any = JSON.parse(dofAuthSessionValuesSession);
          window.sessionStorage.setItem(
            "dofAuthValues",
            JSON.stringify({
              ...dofAuthValues,
              encodeAuthKey: authKey,
              authKey: authKey,
            })
          );
        }
      }
      if (!customerId) {
        const customerIdResp = await getCustomerId(
          decisionEngineDetails?.globalCustomerId,
          setRacPadCustomerDetail,
          setCustomerId
        );
        setCustomer(customerIdResp);
        const customerdetails = await getCustomerDetails(
          customerIdResp,
          setCVOCustomerDetails,
          landlordResidence,
          setLandlordResidence,
          familyReferenceDetails,
          setFamilyReferenceDetails,
          nonFamilyReferenceDetails,
          setnonFamilyReferenceDetails,
          employementDetails, setEmployementDetails
        );
        setCVOCustomerDetails(customerdetails);
        setLoader(false);
      } else {
        const customerdetails = await getCustomerDetails(
          customerId ? customerId : sessionStorageValues?.customerId,
          setCVOCustomerDetails,
          landlordResidence,
          setLandlordResidence,
          familyReferenceDetails,
          setFamilyReferenceDetails,
          nonFamilyReferenceDetails,
          setnonFamilyReferenceDetails,
          employementDetails, setEmployementDetails
        );
        setCVOCustomerDetails(customerdetails);
        setLoader(false);
      }
    };
    fetchCustomerDetails();
    // fetchAndSetAuthKeydetails(custMobNumber);
    // Call sendHeightToParent initially to send the height on mount
    sendHeightToParent();
    // Add an event listener for window resizing
    const handleResize = () => {
      sendHeightToParent();
    };
    window.addEventListener("resize", handleResize);
  }, []);

  const sendHeightToParent = () => {
    const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
    const iframeWidth = document.body.scrollWidth;
    window.parent.postMessage(
      {
        message: "racpad-styling",
        minHeight: iframeHeight < 540 ? 540 : iframeHeight,
        currentHeight: iframeHeight,
        iframeWidth: iframeWidth,
        verificationProcessCompleted: false
      },
      "*"
    );
  };

  return (
    <>

      {loader ? <ScreenLoader /> : null}

      <Grid style={{ width: "100%", height: "80vh" }}>
        <Grid style={{ marginTop: "7%" }}>
          {(atob(clientId) == "EC" || atob(clientId) == 'ECOM') ? null : (
            <Grid style={{ textAlign: "center", display: "inline-flex" }}>
              <img
                src={doflogo}
                style={{
                  float: "left",
                  verticalAlign: "middle",
                  padding: "16px 24px",
                }}
              />
            </Grid>
          )}

          <Typography
            className={`${classes.primaryFontColor} ${classes.responsiveFontTitle} ${classes.boldFont1} ${classes.p10px}`}
          >
            Let’s get started
          </Typography>

          <Grid
            className={`${classes.justifyContentCenter} ${classes.displayFlex}`}
          >
            <Grid
              className={`${classes.p10px} ${classes.displayFlex} ${classes.textAlignLeft} ${classes.flexDirectionColumn}`}
            >
              <Typography
                className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.p10px}`}
              >
                We trust Intellicheck to safely verify your identity. Before you
                start:
              </Typography>
              <List className={`${classes.listStyle} ${classes.pl30px}`}>
                <li className={`${classes.listBottomStyle}`}>
                  Get your state-issued driver’s license or identification card
                </li>
                <li className={`${classes.listBottomStyle}`}>
                  Place it face up on an uncluttered surface
                </li>
                <li className={`${classes.listBottomStyle1}`}>
                  Find a spot with sufficient lighting
                </li>
              </List>
              <Typography
                className={`${classes.p10px} ${classes.openSansRegularFont}`}
              >
                Tap “Start” when you’re ready.
              </Typography>
            </Grid>
          </Grid>
          <Grid className={`${classes.p10px}`}>
            <RACButton
              color="primary"
              variant="contained"
              className={`${classes.primaryButtonColor} ${classes.startButtonWidth}`}
              onClick={() => {
                handleStartBtn();
              }}
            >
              Start
            </RACButton>
          </Grid>
          <Grid className={`${classes.policyGridStyle}`}>
            Information you submit will be subject to Intellicheck’s{" "}
            <a
              href="https://www.intellicheck.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#0046ad", textDecoration: "none" }}
            >
              Privacy Policy
            </a>
            .
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DOFStartingPage;

/* eslint-disable */
import { Route, Switch, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';


import { AppRoute } from '../config/route-config';
import PayfonePrefill from '../components/PayfonePrefill';
import BasicInfo from '../components/BasicInfo';
import EmployerInfo from '../components/EmployerInfo';
import ResidenceInfo from '../components/ResidenceInfo';
import ReferenceInfo from '../components/ReferenceInfo';
import ReviewInfo from '../components/ReviewInfo';
import { ApprovalPage } from '../components/ApprovalPage';
import IdentityVerify from '../components/IdentifyVerify';
import UserInfo from '../components/UserInfo';
import DofStartingPage from '../components/DofStartingPage';

// Customver Verification Optimization Component import starts

import { DOFSubmitted } from '../components/CustomerVerification/VerificationComponents/DOFSubmitted';
import { IdentityTab } from '../components/CustomerVerification/VerificationComponents/IdentityTab';
import { ResidenceTab } from '../components/CustomerVerification/VerificationComponents/ResidenceTab';
import { IncomeTab } from '../components/CustomerVerification/VerificationComponents/Income';
import ReferenceTab from '../components/CustomerVerification/VerificationComponents/ReferenceTab';
import { ReviewInformation } from '../components/CustomerVerification/VerificationComponents/ReviewInformation';
import { WelcomePage } from '../components/CustomerVerification/VerificationComponents/WelcomePage';
import { OTPAuthenticationTab } from '../components/CustomerVerification/VerificationComponents/OTPAuthentication';
import DOFStartingPage from '../components/CustomerVerification/VerificationComponents/DOFStartingPage';
import { getConfigDetails, validateAuthKey, GetApproval } from '../api/user';
import { DigitalOrderFormContext } from '../components/CustomerVerification/Context/DigitialOrderFormContext';
import { ReferenceTabContext } from '../components/CustomerVerification/Context/ReferenceDetailsContext';

import ScreenLoader from '../components/CustomerVerification/Common/ScreenLoader';
import { getCustomerId, dropDownOptionsBinding, MakeFeatureFlagObjFn, setDocumentState, updateNavDetails } from '../components/CustomerVerification/Common/HelperFunctions';
import { OTPVerified } from '../components/CustomerVerification/VerificationComponents/OtpVerified';
import { getCustomerDetails } from '../components/CustomerVerification/Common/GetCustomerDetails';
import { ResidenceTabContext } from '../components/CustomerVerification/Context/ResidenceDetailsContext';
import { dofContextValue } from '../context/DofContext';
export const routesTestId = 'routesTestId';
import { IncomeTabContext } from '../components/CustomerVerification/Context/IncomeDetailsContext';


export const Routes = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const storeCode = queryParams.get("StoreCode");
  const authKey = queryParams.get("AuthKey")

  const { decisionEngineDetails, setDecisionEngineDetails, setCustomerId, setRacPadCustomerDetail, setIncomeDoc, setResidenceDoc, setIdentityFrontDoc, setCVOCustomerDetails, setIntellicheckPassscore, setDynamicHeader, setEcomSubmission, customerId, setNavHeader, setIncomeDocCopy } = useContext(DigitalOrderFormContext)

  let { customerDetails, setCustomerDetails } = useContext(dofContextValue)

  const { setRelationshipOptions, relationshipOptions,
    familyReferenceDetails,
    setFamilyReferenceDetails,
    nonFamilyReferenceDetails,
    setnonFamilyReferenceDetails } = useContext(ReferenceTabContext)
  const { setResidenceTenureTypeOptions, landlordResidence, setLandlordResidence } = useContext(ResidenceTabContext)
  const { employementDetails, setEmployementDetails,
  } = useContext(IncomeTabContext)

  const [screenLoader, setScreenLoader] = useState(false)
  const params = new URLSearchParams(window.location.search);
  const EncodeAuthKey = params.get('AuthKey');
  const EncodedStorecode = params.get('StoreCode') || '';
  const EncodeUserId = params.get('userId') || '';
  const EncodeClientId: any = params.get('clientId')
  const EncodedeId = params.get('deId')
  const EncodeSalesLeadId = params.get('salesLeadId')
  const customerIdparams = params.get('customerId')
  const status = params.get('navpage')

  let dofAuthValues: any = {};
  // To retrieve the object from session storage
  let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
  let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);

  useEffect(() => {
    console.log("storedValues", sessionStorageValues);

    console.log('QUery param values', EncodeAuthKey, customerIdparams, EncodedStorecode, EncodedeId)
    // Ensure sessionStorageValues is valid before checking Object.keys
    if (!sessionStorageValues || Object.keys(sessionStorageValues).length < 1 || EncodeAuthKey != sessionStorageValues?.authKey) {
      if (EncodedStorecode) {
        dofAuthValues.encodeStoreNumber = EncodedStorecode;
        dofAuthValues.storeNumber = atob(EncodedStorecode);
        window.sessionStorage.setItem("storeNumber", atob(EncodedStorecode));
      }
      if (EncodeUserId) {
        dofAuthValues.userId = EncodeUserId;
        dofAuthValues.encodeUserId = atob(EncodeUserId);
        window.sessionStorage.setItem("encodeUserId", atob(EncodeUserId));
      }
      if (EncodeAuthKey) {
        dofAuthValues.encodeAuthKey = EncodeAuthKey;
        dofAuthValues.authKey = EncodeAuthKey;
        window.sessionStorage.setItem("authKey", atob(EncodeUserId));
        window.sessionStorage.setItem("dofAuthValues", JSON.stringify({ ...dofAuthValues, authKey: EncodeAuthKey, encodeAuthKey: EncodeAuthKey }))

      }
      if (EncodeClientId) {
        dofAuthValues.clientId = EncodeClientId;
      }
      if (EncodedeId) {
        dofAuthValues.approvalId = atob(EncodedeId);
        dofAuthValues.encodedApprovalId = EncodedeId;
      }
      if (EncodeSalesLeadId) {
        dofAuthValues.salesLeadId = atob(EncodeSalesLeadId);
        dofAuthValues.encodedSalesLeadId = EncodeSalesLeadId;
      }

      if (!sessionStorageValues || Object.keys(sessionStorageValues).length < 1) {
        window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues))
      }

    }

    // Store the object in sessionStorage

    // Call the validation function
    validateAuthKeyFn(
      EncodeAuthKey ? EncodeAuthKey : sessionStorageValues?.authKey,
      EncodedStorecode ? EncodedStorecode : sessionStorageValues?.encodeStoreNumber
    );

  }, []);


  // Get Config Details Function
  const getConfigDetailsFn = async () => {
    console.log('Query Param values', status, customerIdparams);
    // To retrieve the object from session storage
    let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues")
    let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
    const StoreId = sessionStorageValues?.storeNumber
    const featureFlagReq = {
      storeNumbers: [sessionStorageValues?.storeNumber],
      paramKeyNames: ['DOFCustomerVerification', 'IntellicheckDocumentSides', 'IntellicheckPassScoreLimit', 'CustomerSourceVerificationLevels', 'RequiredDocumentsVerificationLevels'],
    };
    let FeatureFlagResponse: any = '';



    if (sessionStorageValues?.dofFeatureFlag == '1') {
      await getCustomerDetail()
    }
    else if (sessionStorageValues?.dofFeatureFlag == '0') {
      setScreenLoader(false)
      authKey
        ? history.push(
          `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
        )
        : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`); return
    }
    else if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' && StoreId != 'null' && StoreId != 'undefined') {
      setScreenLoader(true)
      FeatureFlagResponse = await getConfigDetails(
        featureFlagReq, sessionStorageValues?.authKey ? false : sessionStorageValues?.storeNumber,
        sessionStorageValues?.authKey ? sessionStorageValues?.authKey : sessionStorageValues?.storeNumber
      );
      setScreenLoader(false)
      if (FeatureFlagResponse?.status == 200) {
        const featureFlagDetails = JSON.stringify(FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
        window.sessionStorage.setItem('featureFlagDetails', featureFlagDetails);

        if (FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails) {
          const FeatureFlagObj = MakeFeatureFlagObjFn(
            FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails
          )
          if (FeatureFlagObj?.DOFCustomerVerification == 1) {
            dofAuthValues.dofFeatureFlag = FeatureFlagObj?.DOFCustomerVerification
            setIntellicheckPassscore(Number(FeatureFlagObj?.IntellicheckPassScoreLimit || ''));
            window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues));
            await getCustomerDetail()
          }
          else {
            dofAuthValues.dofFeatureFlag = '0'
            window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues));

            setScreenLoader(false)

            authKey
              ? history.push(
                `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
              )
              : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);
          }

        }
        else {
          dofAuthValues.dofFeatureFlag = '0'
          window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues));

          setScreenLoader(false)

          authKey
            ? history.push(
              `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
            )
            : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);
        }
      }
      else {

        dofAuthValues.dofFeatureFlag = '0'
        window.sessionStorage.setItem("dofAuthValues", JSON.stringify(dofAuthValues));
        setScreenLoader(false)
        authKey
          ? history.push(
            `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
          )
          : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);
      }
    }
    else {
      setScreenLoader(false)
    }

  };

  // Function for Validating the authKey
  const validateAuthKeyFn = async (EncodeAuthKey?: any, EncodedStorecode?: any) => {
    setScreenLoader(true)
    customerDetails = {
      ...customerDetails,
      storeCode: EncodedStorecode != '' ? atob(EncodedStorecode) : '',
      EncodedStorecode: EncodedStorecode != '' ? EncodedStorecode : '',
    }
    setCustomerDetails(customerDetails)
    if (EncodeAuthKey) {
      let ResponseValidateAuth: any = await validateAuthKey(EncodeAuthKey, false);
      ResponseValidateAuth = ResponseValidateAuth?.data
      console.log(ResponseValidateAuth, 'response');
      if (
        ResponseValidateAuth &&
        ResponseValidateAuth?.valid === true
      ) {
        getConfigDetailsFn()
      }
      else {
        setScreenLoader(false)

        window.parent.postMessage(
          {
            status: 504,
            message: "Authentication timeout. Please try again.",
            verificationProcessCompleted: false
          },
          "*"
        );
        history.push(`/otp/auth/${sessionStorageValues?.approvalId}?session=timeout`)
      }
    } else if (EncodedStorecode) {
      console.log('storeCode flow');
      const DecodeStoreCode = new Buffer(EncodedStorecode, 'base64');
      let DecodedNum = DecodeStoreCode.toString('ascii');
      const ValidateStore = await validateAuthKey(DecodedNum, true);
      console.log(ValidateStore, 'validates');
      if (ValidateStore) {
        getConfigDetailsFn()
      }
      else {
        setScreenLoader(false)

        window.parent.postMessage(
          {
            status: 504,
            message: "Authentication timeout. Please try again.",
            verificationProcessCompleted: false
          },
          "*"
        );
        history.push(`/otp/auth/${sessionStorageValues?.approvalId}?session=timeout`)
      }
    }
  };


  //  Function for getting the customer Details 

  const getCustomerDetail = async () => {
    let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
    let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
    setScreenLoader(true)
    console.log('DOF auth values', sessionStorageValues)
    console.log('query params 12345', status, customerIdparams)
    const request: any = {
      "decisionId": sessionStorageValues?.approvalId
    }
    if (relationshipOptions?.length < 1 && (sessionStorageValues?.approvalId || !EncodedeId && !sessionStorageValues?.approvalId)) {
      dropDownOptionsBinding(setRelationshipOptions, setResidenceTenureTypeOptions)
    }

    if (!EncodedeId && !sessionStorageValues?.approvalId) {
      if (EncodedStorecode) {
        setScreenLoader(false);
        authKey
          ? history.push(
            `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
          )
          : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);
      }
    }
    else {
      // Calling the Get Approval Service
      if (request?.decisionId) {
        const getApprovalServiceResponse: any = await GetApproval(request, sessionStorageValues?.authKey ? false : sessionStorageValues?.storeNumber,
          sessionStorageValues?.authKey ? sessionStorageValues?.authKey : sessionStorageValues?.storeNumber);

        console.log('query params', status, customerIdparams)
        console.log('Get Approval Service Response', getApprovalServiceResponse);
        if (getApprovalServiceResponse && getApprovalServiceResponse?.globalCustomerId) {
          setDecisionEngineDetails(getApprovalServiceResponse)
          updateNavDetails(setDynamicHeader, getApprovalServiceResponse, { identity: false, residence: false, income: false, reference: false, activeStep: false }, setNavHeader)
          setScreenLoader(false);
          getCustomerId(getApprovalServiceResponse?.globalCustomerId, setRacPadCustomerDetail, setCustomerId);
          setDocumentState(request?.decisionId, setIdentityFrontDoc, setResidenceDoc, setIncomeDoc);
          const clientId: any = sessionStorageValues?.clientId;
          if (status == "otpverified") {
            history.push(`/otp/verified/${request?.decisionId}`);
          } else {
            if (status == "idcompleted" && customerIdparams) {
              console.log("Inside the navigation");
              await getCustomerDetails(
                customerIdparams,
                setCVOCustomerDetails,
                landlordResidence,
                setLandlordResidence,
                familyReferenceDetails,
                setFamilyReferenceDetails,
                nonFamilyReferenceDetails,
                setnonFamilyReferenceDetails,
                employementDetails, setEmployementDetails
              );
              setEcomSubmission(true);
              history.push(
                `/identity/${customerIdparams}/${request?.decisionId}?status=completed&get=details`
              );
            } else if (
              (atob(clientId) == "EC" || atob(clientId) == "ECOM") &&
              (storeCode || authKey)
            ) {
              setEcomSubmission(true);
              const pendingDoc =
                getApprovalServiceResponse.verificationDocuments.find(
                  (doc: any) => doc.verificationStatus.toUpperCase() === "PEND"
                );
              if (pendingDoc?.verifyType == "ID") {
                history.push(
                  `/dof/verification/${sessionStorageValues?.approvalId}`
                );
              } else if (pendingDoc?.verifyType == "RESIDENCE") {
                let customerRespId: any = null;
                if (!customerId) {
                  setScreenLoader(true);
                  customerRespId = await getCustomerId(
                    getApprovalServiceResponse?.globalCustomerId,
                    setRacPadCustomerDetail,
                    setCustomerId
                  );
                  const customerdetails = await getCustomerDetails(
                    customerRespId,
                    setCVOCustomerDetails,
                    landlordResidence,
                    setLandlordResidence,
                    familyReferenceDetails,
                    setFamilyReferenceDetails,
                    nonFamilyReferenceDetails,
                    setnonFamilyReferenceDetails,
                    employementDetails, setEmployementDetails
                  );
                  setCVOCustomerDetails(customerdetails);
                }
                setScreenLoader(false);
                console.log("CustomerId from resp", customerId, customerRespId);
                history.push(
                  `/residence/${customerId ? customerId : customerRespId}/${request?.decisionId
                  }`
                );
              } else if (pendingDoc?.verifyType == "EMPLOYMENT") {
                let customerRespId: any = null;
                if (!customerId) {
                  setScreenLoader(true);
                  customerRespId = await getCustomerId(
                    getApprovalServiceResponse?.globalCustomerId,
                    setRacPadCustomerDetail,
                    setCustomerId
                  );
                  const customerdetails = await getCustomerDetails(
                    customerRespId,
                    setCVOCustomerDetails,
                    landlordResidence,
                    setLandlordResidence,
                    familyReferenceDetails,
                    setFamilyReferenceDetails,
                    nonFamilyReferenceDetails,
                    setnonFamilyReferenceDetails,
                    employementDetails, setEmployementDetails
                  );
                  setCVOCustomerDetails(customerdetails);
                }
                setScreenLoader(false);
                console.log("CustomerId from resp", customerId, customerRespId);
                history.push(
                  `/income/${customerId ? customerId : customerRespId}/${request?.decisionId
                  }`
                );
              } else if (pendingDoc?.verifyType == "REFERENCES") {
                let customerRespId: any = null;
                if (!customerId) {
                  setScreenLoader(true);
                  customerRespId = await getCustomerId(
                    getApprovalServiceResponse?.globalCustomerId,
                    setRacPadCustomerDetail,
                    setCustomerId
                  );
                  const customerdetails = await getCustomerDetails(
                    customerRespId,
                    setCVOCustomerDetails,
                    landlordResidence,
                    setLandlordResidence,
                    familyReferenceDetails,
                    setFamilyReferenceDetails,
                    nonFamilyReferenceDetails,
                    setnonFamilyReferenceDetails,
                    employementDetails, setEmployementDetails
                  );
                  setCVOCustomerDetails(customerdetails);
                }
                setScreenLoader(false);
                console.log("CustomerId from resp", customerId, customerRespId);
                history.push(
                  `/reference/${customerId ? customerId : customerRespId}/${request?.decisionId
                  }`
                );
              } else {
                history.push(
                  `/dof/verification/${sessionStorageValues?.approvalId}`
                );
              }
            } else if (storeCode || authKey) {
              history.push(`/welcome/${sessionStorageValues?.approvalId}`);
            }
          }
        }
        else {
          setScreenLoader(false);
          if (status == "otpverified") {
            history.push(`/otp/verified/${request?.decisionId}`);
          } else {
            history.push(`/welcome/${sessionStorageValues?.approvalId}`)
          }
        }
      }
      else {

        authKey
          ? history.push(
            `/dof?AuthKey=${authKey}&userId=${EncodeUserId}&StoreCode=${storeCode}`
          )
          : history.push(`/dof?StoreCode=${storeCode}&userId=${EncodeUserId}`);

      }
    }

  }

  return (

    < div data-testid={routesTestId}>

      {screenLoader ? <ScreenLoader /> : null}
      <Switch>
        <Route
          exact
          path={AppRoute.startingPage}
          key="/"
          component={DofStartingPage}
        />
        <Route
          exact
          path={AppRoute.payfoneprefill}
          key="/payfoneprefill"
          component={PayfonePrefill}
        />
        <Route
          exact
          path={AppRoute.basicInfo}
          key="/basicinfo"
          component={BasicInfo}
        />
        <Route
          exact
          path={AppRoute.employerInfo}
          key="/employerinfo"
          component={EmployerInfo}
        />
        <Route
          exact
          path={AppRoute.residenceInfo}
          key="/residenceinfo"
          component={ResidenceInfo}
        />
        <Route
          exact
          path={AppRoute.referenceInfo}
          key="/referenceinfo"
          component={ReferenceInfo}
        />
        <Route
          exact
          path={AppRoute.reviewInfo}
          key="/reviewInfo"
          component={ReviewInfo}
        />
        <Route
          exact
          path={AppRoute.ApprovalPage}
          key="/ApprovalPage"
          component={ApprovalPage}
        />
        <Route
          exact
          path={AppRoute.identifyVerify}
          key="/IdentityVerify"
          component={IdentityVerify}
        />
        <Route
          exact
          path={AppRoute.userInfo}
          key="/userInfo"
          component={UserInfo}
        />

        <Route
          exact
          path={AppRoute.dofStartPage}
          key="/dof/verification/:approvalId"
          component={DOFStartingPage}
        />
        <Route
          path={AppRoute.welcomepage}
          key="/welcome/:approvalId"
          component={WelcomePage}
        />
        <Route
          path={AppRoute.indentitytab}
          key="/identity/:customerId/:approvalId"
          component={IdentityTab}
        />
        <Route
          path={AppRoute.residenceTab}
          key="/residence/:customerId/:approvalId"
          component={ResidenceTab}
        />
        <Route
          path={AppRoute.incomeTab}
          key="/income/:customerId/:approvalId"
          component={IncomeTab}
        />
        <Route
          path={AppRoute.reference}
          key="/reference/:customerId/:approvalId"
          component={ReferenceTab}
        />
        <Route
          path={AppRoute.reviewInformation}
          key="/reviewInformation/:customerId?/:approvalId?"
          component={ReviewInformation}
        />
        <Route
          path={AppRoute.dofSubmitted}
          key="/dof/submission/:customerId/:approvalId"
          component={DOFSubmitted}
        />
        <Route
          path={AppRoute.dofAuthentication}
          key="/otp/auth/:approvalId"
          component={OTPAuthenticationTab}
        />
        <Route
          path={AppRoute.otpVerified}
          key="/otp/verified/:approvalId"
          component={OTPVerified}
        />

      </Switch>
    </div >
  )


}
/* eslint-disable  */
import React from 'react';
import { Grid, Typography } from "@rentacenter/racstrap";
import { EMPTY_STRING } from "../../../constants/constants";
import { GovernmentIdType, IntellicheckGovtIDType } from "../../../constants/enums";
import { CustomerInfo } from "../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel";
import { VerificationStyles } from '../../../styles/verificationStyles';
import { generateDOFAccessLink, GetCustomerDetails, GetDropOptions, getVerificationDocuments, PublishDOFNotification, StoreActionItemInsertion, UpdateCustomerInfo, uploadVerificationDocuments } from '../../../api/user';
import jsPDF from 'jspdf';
import Compressor from 'compressorjs';
// import imageCompression from 'browser-image-compression'


// Helper function to safely access nested properties and return a default value if the property is not found.
export const getValue = (data: any, path: string, defaultValue: string = EMPTY_STRING) => {
  // Split the path into an array of keys
  const pathParts = path.split('.');

  // Use reduce to traverse the path and access the nested value
  const value = pathParts.reduce((acc, part) => acc?.[part], data);

  // Return the found value or the default value if it's undefined or null
  return value ?? defaultValue;
};

// Helper function to safely access an array at a given path, returning an empty array if not found.
export const getArrayValue = (data: any, path: string) => {
  // Split the path into an array of keys
  const pathParts = path.split('.');

  // Traverse the object along the path to get the value
  const value = pathParts.reduce((acc, part) => acc?.[part], data);

  // Return the value if it's an array, otherwise return an empty array
  return Array.isArray(value) ? value : [];
};

// Function to determine the ID document type based on government ID type
export const getIdDocumentType = (customerDetails: CustomerInfo) => {
  const governmentIdType = customerDetails?.taxInfo?.governmentIdType;

  switch (governmentIdType) {
    case GovernmentIdType.DL:
    case GovernmentIdType.UDL:
      return IntellicheckGovtIDType.na_dl;
    case GovernmentIdType.PASS:
    case GovernmentIdType.UPASS:
      return IntellicheckGovtIDType.passport;
    default:
      return IntellicheckGovtIDType.other;
  }
};

/* 
  This function is used to dynamically bind headings based on the Tab. 
  Just provide the tab name, and it will return the heading.
*/
export const getTabsHeading = (tabName: string) => {
  const classes: any = VerificationStyles();
  return (
    <Grid className={classes.headingWidth}>
      <Typography className={`${classes.heading}`}>{`Please give us some details about your ${tabName}`}</Typography>
    </Grid>
  )
}

export const getNoteText = () => {
  const classes: any = VerificationStyles();
  return (
    <Grid className={`${classes.w85p}`}>
      <Typography className={`${classes.font14px}`}><span className={`${classes.boldFont1}`}>NOTE: </span>If you prefer to continue with a store coworker, please call or visit your store.</Typography>
    </Grid>
  )
}
export interface dropdown {

  label: string;

  value: string;

  paydayid?: number;

}





export const buildDropdownList = (optionsList: any): dropdown[] => {

  const options: dropdown[] = [{ value: '', label: 'Select' }];

  const referenceOptions = optionsList.filter((obj: any) => obj.referenceCode != 'REL')
  // eslint-disable-next-line no-console
  console.log("referenceOptions", referenceOptions)

  if (

    referenceOptions !== undefined &&

    referenceOptions !== null &&

    referenceOptions.length > 0

  ) {

    referenceOptions.map((val: any) => {

      options.push({ label: val.description, value: val.referenceCode });

    });

  }



  return options;

};

export const updateCustomerApproval = async (tabName: string, decisionId: string, customerId: string, updateDetails?: any) => {
  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const clientId: any = sessionStorageValues?.clientId
  console.log("clientidvalueapproval", clientId)
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;

  const payload = {
    customerId: customerId,
    deDecisionId: decisionId,
    isManageApproval: true,
    verificationDocuments: [
      {
        verificationStatus: updateDetails ? updateDetails?.status : "PENDREW",
        verifyType: tabName,
        modifiedBy: updateDetails
          ? updateDetails?.modifiedBy
          : clientId
            ? (atob(clientId) === 'EC' || atob(clientId) === 'ECOM'
              ? 'EC'
              : atob(clientId) === 'SF'
                ? 'SF'
                : atob(clientId) === 'RACPAD'
                  ? 'RACPAD'
                  : 'DOF'
            )
            : 'DOF',
      },
    ],
  };
  console.log('Payload for Update Approval', payload);
  await UpdateCustomerInfo(payload, isStoreCodeFlow, accessToken)
}

export const uploadDocuments = async (decisionId: string, documents: any) => {
  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;
  const payload: any = {
    isVerificationDocument: true,
    customerApprovalId: decisionId,
    documents: documents,
  };

  const uploadDocRes: any = await uploadVerificationDocuments(payload, isStoreCodeFlow, accessToken);

  // eslint-disable-next-line no-console
  console.log('Upload Documents Res', uploadDocRes)

  if (uploadDocRes?.status == 200) {
    // eslint-disable-next-line no-console
    console.log('Upload document sucess', uploadDocRes?.data)
  } else {
    const documentType = documents[0]?.documentType == 'EMPDOC' ? 'Income' : documents[0]?.documentType == 'IDENTTY' ? 'Intellicheck' : documents[0]?.documentType == 'RESDOC' ? 'Residence' : ''
    // eslint-disable-next-line no-console
    console.log('Something went wrong document upload', uploadDocRes?.data)
    window.parent.postMessage({
      status: 400,
      message: `The ${documentType} Document verification failed due to an technical issue.`,
      verificationProcessCompleted: false
    },
      "*")
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCustomerId = async (gcid: string, setRacPadCustomerDetail: any, setCustomerId: any) => {
  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;
  const getCustomerDetailResp: any = await GetCustomerDetails({ GcId: gcid }, isStoreCodeFlow, accessToken);

  // eslint-disable-next-line no-console
  console.log('Detail Service Response', getCustomerDetailResp, getCustomerDetailResp?.status == 200, getCustomerDetailResp?.data);

  if (getCustomerDetailResp && getCustomerDetailResp?.status == 200 && getCustomerDetailResp?.data) {
    // eslint-disable-next-line no-console
    console.log('Inside the getCustomerDetailResp', getCustomerDetailResp)
    setRacPadCustomerDetail(getCustomerDetailResp.data);
    setCustomerId(getCustomerDetailResp.data?.customerId);
    window.sessionStorage.setItem("customerId", getCustomerDetailResp.data?.customerId)
    return getCustomerDetailResp.data?.customerId
  } else {
    return ''
  }

}

export const convertImageToPDF = async (imageBase64: any) => {
  // eslint-disable-next-line no-console
  console.log(
    "Image Base4",
    imageBase64.replace(/^data:image\/png;base64,/, "")
  );
  const pdf = new jsPDF();

  // Remove the 'data:image/jpeg;base64,' part of the string
  const base64Image = imageBase64.replace(
    /^data:image\/(png|jpeg);base64,/,
    ""
  );

  const img = new Image();
  img.src = `data:image/jpeg;base64,${base64Image}`; // Prefix it again for img.src

  // img.onload = async () => {
  //   // Get the natural width and height of the image
  //   const imgWidth = img.width;
  //   const imgHeight = img.height;

  //   // Get the page size of the PDF (A4 by default)
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   // Calculate the scaling factor to fit the image on the page while maintaining the aspect ratio
  //   const scaleFactor = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

  //   // Calculate the new dimensions to fit within the PDF page size
  //   const scaledWidth = imgWidth * scaleFactor;
  //   const scaledHeight = imgHeight * scaleFactor;

  //   // Add the image to the PDF with the calculated dimensions
  //   pdf.addImage(`data:image/jpeg;base64,${base64Image}`, 'JPEG', 0, 0, scaledWidth, scaledHeight);
  //   const pdfBlob: any = await pdf.output("blob"); // Generate PDF Blob
  //   // eslint-disable-next-line no-console
  //   console.log('PDF Blob', pdfBlob);
  //   return pdfBlob;
  // };

  // img.onerror = (err) => {
  //   // eslint-disable-next-line no-console
  //   console.error("Error loading image:", err);
  // };
  return new Promise<Blob>((resolve, reject) => {
    img.onload = () => {
      // Get the natural width and height of the image
      const imgWidth = img.width;
      const imgHeight = img.height;

      // Get the page size of the PDF (A4 by default)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the scaling factor to fit the image on the page while maintaining the aspect ratio
      const scaleFactor = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      // Calculate the new dimensions to fit within the PDF page size
      const scaledWidth = imgWidth * scaleFactor;
      const scaledHeight = imgHeight * scaleFactor;

      // Add the image to the PDF with the calculated dimensions
      pdf.addImage(`data:image/jpeg;base64,${base64Image}`, 'JPEG', 0, 0, scaledWidth, scaledHeight);

      // Generate PDF Blob and resolve the promise
      const pdfBlob: Blob = pdf.output("blob"); // Generate PDF Blob
      resolve(pdfBlob);
    };

    img.onerror = (err) => {
      // eslint-disable-next-line no-console
      console.error("Error loading image:", err);
      reject(err);  // Reject if there's an error loading the image
    };
  });
};

export const convertPDFToBlobURL = (pdfFile: Blob) => {
  return new Promise<any>((resolve, reject) => {
    try {
      // Generate an object URL for the PDF Blob
      const url = URL.createObjectURL(pdfFile);
      console.log('PDF Blob Url', url);
      resolve({ url }); // Resolve with the generated Blob URL
    } catch (err) {
      // If there's an error, reject the promise
      reject(err);
    }
  });
};

export const convertPDFToBase64 = async (pdfFile: Blob) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const reader = new FileReader();
      const result = await convertPDFToBlobURL(pdfFile);
      reader.onloadend = () => {
        // Once the reader finishes reading, resolve with the base64 string
        const base64String = (reader.result as string).split(',')[1]; // Strip off the data URL prefix
        resolve({
          url: result?.url,
          base64String
        });
      };
      reader.readAsDataURL(pdfFile);
      // Resolve with the generated URL
    } catch (err) {
      // If there's an error, reject the promise
      reject(err);
    }
  });
};

export const b64toBlob = (
  b64Data: any,
  contentType = "application/pdf",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const formatMobileNumber = (mobileNumber: string) => {
  return (`(${mobileNumber?.slice(0, 3)}) ${mobileNumber?.slice(
    3,
    6
  )}-${mobileNumber?.slice(6, 10)}`)
}

// export const handleFile = async (file: any) => {
//   const fileType = file.type;
//   // eslint-disable-next-line no-console
//   console.log('File Type', fileType, file)
//   if (fileType === "application/pdf") {
//     // If it's a PDF, convert it to Base64
//     const pdfData: any = await convertPDFToBase64(file);
//     return pdfData;
//   } else if (fileType && fileType.startsWith("image/")) {
//     // If it's an image (JPEG, PNG), convert it to Base64 and then to PDF
//     const reader: any = new FileReader();
//     reader.onloadend = async () => {
//       const imageB64String: any = await convertImageToPDF(reader.result);
//       // eslint-disable-next-line no-console
//       console.log('Image b64 string', imageB64String);
//       const base64Pdf: any = await convertPDFToBase64(imageB64String);
//       // eslint-disable-next-line no-console
//       console.log('Image PDF b64 string', base64Pdf);
//       return base64Pdf;
//     };
//     reader.readAsDataURL(file); // Convert image to Base64
//   } else if (
//     !fileType &&
//     file?.split(",")[0]?.includes("data:image/jpeg;base64")
//   ) {
//     const imageB64String: any = await convertImageToPDF(file?.split(",")[1]);
//     const base64Pdf: any = await convertPDFToBase64(imageB64String);
//     return base64Pdf;
//   }
// };

export const handleFile = async (file: any) => {
  const fileType = file.type;
  console.log('File Type', fileType, file);
  if (fileType === "application/pdf") {
    const pdfData: any = await convertPDFToBase64(file);
    return pdfData;
  } else if (fileType && fileType.startsWith("image/")) {
    return new Promise((resolve, reject) => {
      // Check if file exists
      if (!file) {
        return reject("No file selected");
      }

      // Maximum file size (2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      let compressedFile: any = file;

      const compressImage = (file: any, quality = 0.8) => {
        return new Promise((resolve, reject) => {
          new Compressor(file, {
            quality, // Set quality (0.8 is default)
            maxWidth: 1024, // Max width (optional)
            maxHeight: 1024, // Max height (optional)
            success(result) {
              if (result.size <= maxSize) {
                // If the compressed image is under 2MB, resolve with Base64
                convertBlobToBase64(result)
                  .then((base64) => resolve(base64))
                  .catch((err) =>
                    reject("Error converting Blob to Base64: " + err)
                  );
              } else {
                // If file is still too large, lower the quality and retry
                if (quality > 0.1) {
                  // Try compressing with a lower quality
                  compressImage(result, quality - 0.1)
                    .then(resolve)
                    .catch(reject);
                } else {
                  reject("Image size exceeds 2MB even after compression.");
                }
              }
            },
            error(err) {
              reject("Error during compression: " + err);
            },
          });
        });
      };

      // Start image compression
      compressImage(compressedFile)
        .then(async (compressFile: any) => {
          // Convert compressed file to Base64
          compressedFile = compressFile;
          console.log('compressed File', compressedFile);
          const imageB64String: any = await convertImageToPDF(
            compressedFile?.split(",")[1]
          );
          const base64Pdf: any = await convertPDFToBase64(imageB64String);
          resolve(base64Pdf);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else if (
    !fileType &&
    file?.split(",")[0]?.includes("data:image/jpeg;base64")
  ) {
    return new Promise((resolve, reject) => {
      // Check if file exists
      if (!file) {
        return reject("No file selected");
      }

      // Maximum file size (2MB)
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      let compressedFile: any = file;

      const compressImage = (file: any, quality = 0.8) => {
        return new Promise((resolve, reject) => {
          new Compressor(file, {
            quality, // Set quality (0.8 is default)
            maxWidth: 1024, // Max width (optional)
            maxHeight: 1024, // Max height (optional)
            success(result) {
              if (result.size <= maxSize) {
                // If the compressed image is under 2MB, resolve with Base64
                convertBlobToBase64(result)
                  .then((base64) => resolve(base64))
                  .catch((err) =>
                    reject("Error converting Blob to Base64: " + err)
                  );
              } else {
                // If file is still too large, lower the quality and retry
                if (quality > 0.1) {
                  // Try compressing with a lower quality
                  compressImage(result, quality - 0.1)
                    .then(resolve)
                    .catch(reject);
                } else {
                  reject("Image size exceeds 2MB even after compression.");
                }
              }
            },
            error(err) {
              reject("Error during compression: " + err);
            },
          });
        });
      };

      // Start image compression
      compressImage(compressedFile)
        .then(async (compressFile: any) => {
          // Convert compressed file to Base64
          compressedFile = compressFile;
          console.log('compressed File', compressedFile);
          const imageB64String: any = await convertImageToPDF(
            compressedFile?.split(",")[1]
          );
          const base64Pdf: any = await convertPDFToBase64(imageB64String);
          resolve(base64Pdf);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Handle unsupported file types
  throw new Error("Unsupported file type");
};
export const dropDownOptionsBinding = async (setRelationshipOptions: any, setResidenceTenureTypeOptions?: any) => {
  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;
  const dropOptionsPayload = {
    references: [
      'RELATIONSHIP_TYPE',
      'RESIDENCE_TENURE_TYPE'
    ],
    status: 'Y',
  };
  const getDropOptionsRes: any = await GetDropOptions(dropOptionsPayload, isStoreCodeFlow, accessToken)
  if (getDropOptionsRes) {
    setRelationshipOptions(
      buildDropdownList(getDropOptionsRes?.relationshipType)
    );
    setResidenceTenureTypeOptions(buildDropdownList(getDropOptionsRes?.residenceTenureType))

  }
}

const convertBlobToBase64 = (blob: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result); // Base64 string
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob); // Read the blob as a Base64 string
  });
};

export const formatText = (value: any): string => {
  return value && value.trim().length == 0
    ? value.trim().replace(/[^A-Z'.;’ a-z]/gi, "")
    : value.replace(/[^A-Z'.;’ a-z]/gi, "");
};
export const formatTextField = (value: any): string => {
  return value && value.trim().length == 0
    ? value.trim().replace(/[^A-Za-z.'.\s]/g, "")
    : value.replace(/[^A-Za-z.'.\s]/g, "");
};
export function MakeFeatureFlagObjFn(storeValue: any) {
  const tempFeatureFlagObj: any = {};
  for (let i = 0; i < storeValue?.length; i++) {
    tempFeatureFlagObj[storeValue[i]?.paramKeyName] = storeValue[i]?.paramValue;
  }
  console.log('tempFeatureFlagObj', tempFeatureFlagObj);
  return tempFeatureFlagObj;
}

export function getPendingPriorityDocumentName(response: any): string | null {
  // Define levels based on the question
  const level1 = ['ID', 'RESIDENCE'];
  const level2 = ['ID', 'RESIDENCE', 'EMPLOYMENT'];
  const level3 = ['ID', 'RESIDENCE', 'EMPLOYMENT', 'REFERENCES'];
  const level4 = ['ID', 'RESIDENCE', 'EMPLOYMENT', 'REFERENCES'];

  // Check if verification status is PENDING
  if (response.verificationStatus !== 'PENDING') {
    return null;
  }

  // Check if verificationDocuments array has length
  if (!Array.isArray(response.verificationDocuments) || response.verificationDocuments.length === 0) {
    return null;
  }

  // Get the verification level from the response
  const verifyLevel = response.verifyLevel;

  // Based on the verification level, select the appropriate document types
  let requiredDocuments: string[];
  switch (verifyLevel) {
    case '1':
      requiredDocuments = level1;
      break;
    case '2':
      requiredDocuments = level2;
      break;
    case '3':
    case '-1':
      requiredDocuments = level3;
      break;
    default:
      return null;
  }

  // Check for pending documents in the specified priority order
  for (const docType of requiredDocuments) {
    const pendingDoc = response.verificationDocuments.find((doc: any) =>
      doc.verifyType === docType && doc.verificationStatus.toUpperCase() === 'PEND'
    );
    if (pendingDoc) {
      switch (docType) {
        case 'ID':
          return 'identity';
        case 'RESIDENCE':
          return 'residence';
        case 'EMPLOYMENT':
          return 'income';
        case 'REFERENCES':
          return 'reference';
      }
    }
  }

  // If no documents with 'PEND' status found, return null
  return null;
}


export const setDocumentState = async (approvalId: any, setIdentityFrontDoc: any, setResidenceDoc: any, setIncomeDoc: any) => {
  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;
  const request: any = {
    identifier: approvalId,
    documentStatus: "signed",
    actionType: "print",
    identifierType: "CUST",
    customerVerificationDocument: "true"
  }

  const getVerificationDocumentsRes: any = getVerificationDocuments(request, isStoreCodeFlow, accessToken)

  if (getVerificationDocumentsRes && getVerificationDocumentsRes?.data && getVerificationDocumentsRes?.status == 200 && getVerificationDocumentsRes?.data?.documents?.length > 0) {

    const documents: any = getVerificationDocumentsRes?.data?.documents

    // Helper function to get the most recent document of each type
    const getMostRecentDocument = (docType: string): Document | undefined => {
      // Filter documents by type
      const filteredDocs = documents.filter((doc: any) => doc.documentName === docType);

      // Sort by createdDate in descending order to get the most recent document
      filteredDocs.sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

      // Return the most recent document (or undefined if no documents)
      return filteredDocs.length > 0 ? filteredDocs[0] : undefined;
    };

    // Set the state for each document type
    const identityDoc = getMostRecentDocument("IdentityDocument");
    const residenceDoc = getMostRecentDocument("ResidenceDocument");
    const incomeDoc = getMostRecentDocument("EmploymentDocument");

    // Call the respective setState functions
    console.log('documents response', residenceDoc, incomeDoc);
    setIdentityFrontDoc(identityDoc);
    setResidenceDoc(residenceDoc);
    setIncomeDoc(incomeDoc);
  }

}


export const PublishDOFNotificationFn = async (getApprovalResponse: any, getCustomerRespone: any, salesLeadId: any, sessionStorageValues: any) => {

  const payload = mapCustomerAndApprovalDetailsToPayload(getCustomerRespone, getApprovalResponse, sessionStorageValues);


  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;

  PublishDOFNotification(payload, isStoreCodeFlow, accessToken)
  const storeActionItemCreationPayload: any = {
    firstName: payload?.firstName ? payload?.firstName : '',
    lastName: payload?.lastName ? payload?.lastName : '',
    storeNumber: payload?.storeNumber ? payload?.storeNumber : '',
    storeActionCategory: 'CUS',
    storeActionType: 'CVREVPEN',
    customerId: payload?.customerId ? payload?.customerId : '',
    categoryActionId: payload?.customerId ? payload?.customerId : '',
    redirectURL: `/customer/update/${payload?.customerId}/customer`,
    actionItemDescription: `A verification is pending review for ${payload?.firstName + ' ' + payload?.lastName}`,
    storeActionItemCreation: true

  }
  await StoreActionItemInsertion(storeActionItemCreationPayload, isStoreCodeFlow, accessToken)

}
const mapCustomerAndApprovalDetailsToPayload = (customerDetails: any, approvalDetails: any, sessionStorageValues: any): any => {
  // Helper function to return null if the value is empty, null, undefined, or doesn't exist
  const getNullable = (value: any) => {
    return (value === undefined || value === null || value === "" || (typeof value === "object" && Object.keys(value).length === 0)) ? null : value;
  };

  // Map the customer and approval details to the payload format
  const payload: any = {
    customerId: getNullable(customerDetails?.personalInfo?.customerId),
    firstName: getNullable(customerDetails?.personalInfo?.firstName),
    lastName: getNullable(customerDetails?.personalInfo?.lastName),
    emailAddress: getNullable(customerDetails?.personalInfo?.emailAddress),
    dateOfBirth: getNullable(customerDetails?.personalInfo?.dateOfBirthEnc), // Using decrypted DOB
    addresses: (customerDetails?.addresses || [])
      .filter((address: any) => getNullable(address?.active) === "Y") // Filter for active addresses
      .map((address: any) => ({
        addressType: getNullable(address?.addressType),
        addressLine1: getNullable(address?.addressLine1),
        addressLine2: getNullable(address?.addressLine2),
        city: getNullable(address?.city),
        state: getNullable(address?.state),
        postalCode: getNullable(address?.postalCode)
      })) || [],
    phones: (customerDetails?.phones || [])
      .filter((phone: any) => getNullable(phone?.active) === "Y") // Filter for active phones
      .map((phone: any) => ({
        phoneNumber: getNullable(phone?.phoneNumber),
        phoneType: getNullable(phone?.phoneType),
        primary: getNullable(phone?.primary),
        callTimeType: getNullable(phone?.callTimeType),
        active: getNullable(phone?.active)
      })) || [],
    taxId: getNullable(customerDetails?.taxInfo?.taxIdEnc), // Using decrypted Tax ID
    preferredLanguage: getNullable(customerDetails?.personalInfo?.preferredLanguage),
    storeNumber: getNullable(sessionStorageValues?.storeNumber),
    decisionId: getNullable(approvalDetails?.decisionId),
    globalCustomerId: getNullable(approvalDetails?.globalCustomerId),
    approvalDate: getNullable(approvalDetails?.approvalDate),
    approvalStatusCode: getNullable(approvalDetails?.approvalStatusCode),
    approvalStatusDesc: getNullable(approvalDetails?.approvalStatusDesc),
    approvalOriginator: getNullable(approvalDetails?.approvalOriginator),
    approvalReason: getNullable(approvalDetails?.approvalReason),
    approvalResponse: getNullable(approvalDetails?.approvalResponse),
    verifyCode: getNullable(approvalDetails?.verifyCode),
    deScore: getNullable(approvalDetails?.deScore),
    expVerifyStatus: getNullable(String(approvalDetails?.expVerifyStatus)),
    expVerifyReportId: getNullable(approvalDetails?.expVerifyReportId),
    verifyLevel: getNullable(approvalDetails?.verifyLevel),
    verificationStatus: getNullable(approvalDetails?.verificationStatus),
    verificationDocuments: (approvalDetails?.verificationDocuments || [])
      .filter((doc: any) => doc?.verificationStatus === 'PENDREW')
      .map((doc: any) => ({
        verifyType: getNullable(doc?.verifyType),
        descEn: getNullable(doc?.descEn),
        descEs: getNullable(doc?.descEs),
        details: getNullable(doc?.details),
        detailsEs: getNullable(doc?.detailsEs),
        verifyRequired: getNullable(doc?.verifyRequired),
        verified: getNullable(doc?.verified),
        verificationStatus: getNullable(doc?.verificationStatus),
        verificationStatusDesc: getNullable(doc?.verificationStatusDesc),
        override: getNullable(doc?.override),
        overrideDate: getNullable(doc?.overrideDate),
        clientId: getNullable(doc?.clientId),
        createdBy: getNullable(doc?.createdBy),
        createdDate: getNullable(doc?.createdDate),
        lastModifiedBy: getNullable(doc?.lastModifiedBy),
        lastModifiedDate: getNullable(doc?.lastModifiedDate)
      })) || [],
    bavVerifyLevel: getNullable(approvalDetails?.bavVerifyLevel),
    docVerifyLevel: getNullable(approvalDetails?.docVerifyLevel),
    eventType: 1,
    salesLeadId: getNullable(sessionStorageValues?.salesLeadId),
    originator: getNullable(sessionStorageValues?.clientId) ? atob(sessionStorageValues?.clientId) : ''
  };

  return payload;
};

export function updateNavDetails(setterFucntion: any, getApprovalResponse: any, { identity, residence, income, reference, activeStep }: any, setNavHeader: any): any {

  // Define levels based on the question
  const level1 = ['ID', 'RESIDENCE'];
  const level2 = ['ID', 'RESIDENCE', 'EMPLOYMENT'];
  const level3 = ['ID', 'RESIDENCE', 'EMPLOYMENT', 'REFERENCES'];

  // Check if verificationDocuments array has length
  if (!Array.isArray(getApprovalResponse.verificationDocuments) || getApprovalResponse.verificationDocuments.length === 0) {
    setterFucntion([]);
    return
  }
  // Get the verification level from the response
  const verifyLevel = getApprovalResponse.verifyLevel;
  // Based on the verification level, select the appropriate document types
  let documents: string[];
  const requiredDocuments: any = []
  switch (verifyLevel) {
    case '1':
      documents = level1;
      break;
    case '2':
      documents = level2;
      break;
    case '3':
    case '-1':
      documents = level3;
      break;
    default:
      return null;
  }

  const requiredNavDocuments: any = [];
  for (const docType of documents) {
    switch (docType) {
      case 'ID':
        requiredNavDocuments.push({ label: "Identity", isCompleted: identity, isActive: activeStep === "Identity" });
        break
      case 'RESIDENCE':
        requiredNavDocuments.push({ label: "Residence", isCompleted: residence, isActive: activeStep === "Residence" });
        break
      case 'EMPLOYMENT':
        requiredNavDocuments.push({ label: "Income", isCompleted: income, isActive: activeStep === "Income" });
        break
      case 'REFERENCES':
        requiredNavDocuments.push({ label: "Reference", isCompleted: reference, isActive: activeStep === "Reference" });
        break
    }
  }
  setNavHeader(requiredNavDocuments)

  // Check for pending documents in the specified priority order
  for (const docType of documents) {
    const pendingDoc = getApprovalResponse.verificationDocuments.find((doc: any) =>
      doc.verifyType === docType && doc.verificationStatus.toUpperCase() === 'PEND'
    );
    if (pendingDoc) {
      switch (docType) {
        case 'ID':
          requiredDocuments.push({ label: "Identity", isCompleted: identity, isActive: activeStep === "Identity" });
          break
        case 'RESIDENCE':
          requiredDocuments.push({ label: "Residence", isCompleted: residence, isActive: activeStep === "Residence" });
          break
        case 'EMPLOYMENT':
          requiredDocuments.push({ label: "Income", isCompleted: income, isActive: activeStep === "Income" });
          break
        case 'REFERENCES':
          requiredDocuments.push({ label: "Reference", isCompleted: reference, isActive: activeStep === "Reference" });
          break
      }
    }
  }
  setterFucntion(requiredDocuments)
}

export function getNextNavigator(current: any, navigationDetails: any): any {
  console.log("currentnavigationDetails", current, navigationDetails)
  if (navigationDetails?.length > 0) {
    const currentIndex = navigationDetails.findIndex((element: any) => element?.label == current)
    if (navigationDetails?.length > currentIndex + 1) {
      return navigationDetails[currentIndex + 1].label.toLowerCase()
    }
    else {
      return 'review/details'
    }
  }
  else {
    return 'dof/submission'
  }

}
export function getBackNavigator(current: any, navigationDetails: any): any {
  console.log("getBackNavigator", current, navigationDetails)
  if (navigationDetails?.length > 0) {
    const currentIndex = navigationDetails.findIndex((element: any) => element?.label == current)
    if (currentIndex > 0) {
      return navigationDetails[currentIndex - 1].label.toLowerCase()
    }
    else {
      return null
    }
  }
  else {
    return null
  }

}

// export const fetchAndSetAuthKeydetails = async (custMobNumber: any) => {
//   const dofAuthSessionValue: any =
//     window.sessionStorage.getItem("dofAuthValues");
//   const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
//   console.log("Session Storage details", sessionStorageValues);
//   const isStoreCodeFlow: any = sessionStorageValues?.authKey
//     ? false
//     : sessionStorageValues?.storeNumber;
//   const accessToken: any = sessionStorageValues?.authKey
//     ? sessionStorageValues?.authKey
//     : sessionStorageValues?.storeNumber;
//   if (!sessionStorageValues?.authkey) {
//     const generateLinkPayload: any = {
//       source: atob(sessionStorageValues?.clientId),
//       userId: sessionStorageValues?.encodeUserId,
//       storeNumber: sessionStorageValues?.storeNumber,
//       approvalId: sessionStorageValues?.approvalId,
//       phoneNumber: custMobNumber,
//       sendLinkToCustomer: false,
//     };

//     const generateLinkResp = await generateDOFAccessLink(
//       generateLinkPayload,
//       isStoreCodeFlow,
//       accessToken
//     );

//     console.log("GenerateLinkResp", generateLinkResp);
//     if (generateLinkResp?.status == 200 && generateLinkResp?.data?.digitalOrderFormURL) {
//       const url = generateLinkResp?.data?.digitalOrderFormURL;
//       const urlParams = new URLSearchParams(new URL(url).search);
//       const authKey = urlParams.get("AuthKey");
//       const dofAuthSessionValuesSession: any = window.sessionStorage.getItem("dofAuthValues");
//       const dofAuthValues: any = JSON.parse(dofAuthSessionValuesSession);
//       window.sessionStorage.setItem("dofAuthValues", JSON.stringify({ ...dofAuthValues, encodeAuthKey: authKey, authKey: authKey }))
//     }
    
//   }
// };




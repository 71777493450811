/* eslint-disable */

export const ProductType = 'RPDPLACEHOLDER';

export enum ProductPermissions {
  PLACEHOLDER = 'PLACEHOLDER',
}

import { dropdown } from '../interfaces/interfaces';

export const numericRegex = /[^\d]/g;
export const alphabeticRegex = /[^A-Za-z' .,-]/gi;
export const onlyAlphabeticRegex = /[^a-zA-Z ]/g;
export const alphaNumericRegex = /[^0-9a-zA-Z# .,-]/gi;
export const emailFormat =
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
export const dropOptionsPayload = {
  references: [
    'CALL_TIME',
    'GOVERNMENT_ID_TYPE',
    'RELATIONSHIP_TYPE',
    'EMPLOYER_PAYSCHEDULE',
    'DE_EXCEPTION_REASON',
  ],
  status: 'Y',
};

export const howLongOptions: dropdown[] = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: '1',
    label: '0 to 1 year',
  },
  {
    value: '2',
    label: '1 to 5 years',
  },
  {
    value: '3',
    label: '5+ years',
  },
];

export const EMPTY_STRING = "";
export const BUSINESS_UNIT = 'rac';
export const NA_DL_INTELLICHECK = "na_dl";

export const UNITED_STATES = 'US'

export const CUSTOMER_INFO_TEMPLATE = {
  personalInfo: {
    customerId: EMPTY_STRING,
    globalCustomerId: EMPTY_STRING,
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    middleInitial: EMPTY_STRING,
    dateOfBirth: EMPTY_STRING,
    dateOfBirthEnc: EMPTY_STRING,
    dateOfBirthDec: EMPTY_STRING,
    emailAddress: EMPTY_STRING,
    preferredContactMethod: EMPTY_STRING,
    preferredLanguage: EMPTY_STRING,
    salutation: EMPTY_STRING,
    salutationDesc: EMPTY_STRING,
    suffix: EMPTY_STRING,
    suffixDesc: EMPTY_STRING,
    salesLeadId: EMPTY_STRING,
    createdBy: EMPTY_STRING
  },
  taxInfo: {
    taxId: EMPTY_STRING,
    taxIdEnc: EMPTY_STRING,
    taxIdDec: EMPTY_STRING,
    taxIdType: EMPTY_STRING,
    governmentId: EMPTY_STRING,
    governmentIdEnc: EMPTY_STRING,
    governmentIdDec: EMPTY_STRING,
    governmentIdType: EMPTY_STRING,
    governmentIdTypeDesc: EMPTY_STRING,
    governmentIdIssuedState: EMPTY_STRING,
  },
  solicitationPreferences: {
    phoneSolicitationAllowed: EMPTY_STRING,
    emailSolicitationAllowed: EMPTY_STRING,
    mailSolicitationAllowed: EMPTY_STRING,
    textSolicitationAllowed: EMPTY_STRING,
    paymentReminderCallsAllowed: EMPTY_STRING,
    isNDNCOpted: EMPTY_STRING,
    isTrnsMsgAllowed: EMPTY_STRING,
    isMktgMsgAllowed: EMPTY_STRING,
  },
  paymentPreferences: {
    acceptCheck: EMPTY_STRING,
    remotePaymentAllowed: EMPTY_STRING,
    achAllowed: EMPTY_STRING,
  },
  agreementInfo: {
    activeAgreements: EMPTY_STRING,
    inActiveAgreements: EMPTY_STRING,
    pifAgreements: EMPTY_STRING,
    epoAgreements: EMPTY_STRING,
    coCustomer: EMPTY_STRING,
    coCustomerId: EMPTY_STRING,
  },
  residenceInfo: {
    residenceSince: EMPTY_STRING,
    residenceType: EMPTY_STRING,
  },
  legalInfo: {
    legalHold: EMPTY_STRING,
    verified: EMPTY_STRING,
    verifiedDate: EMPTY_STRING,
  },
  customerRoutes: [
    {
      customerRouteId: EMPTY_STRING,
      storeRouteId: EMPTY_STRING,
      storeNumber: EMPTY_STRING,
      routeCodeDesc: EMPTY_STRING,
    },
  ],
  addresses: [
    {
      addressId: EMPTY_STRING,
      addressType: EMPTY_STRING,
      addressTypeDesc: EMPTY_STRING,
      addressLine1: EMPTY_STRING,
      addressLine2: EMPTY_STRING,
      city: EMPTY_STRING,
      state: EMPTY_STRING,
      postalCode: EMPTY_STRING,
      latitude: EMPTY_STRING,
      longitude: EMPTY_STRING,
      active: EMPTY_STRING,
      verifiedDate: EMPTY_STRING,
    },
  ],
  phones: [
    {
      phoneId: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      phoneType: EMPTY_STRING,
      phoneTypeDesc: EMPTY_STRING,
      primary: EMPTY_STRING,
      callTimeType: EMPTY_STRING,
      callTimeTypeDesc: EMPTY_STRING,
      active: EMPTY_STRING,
      isNDNCOpted: EMPTY_STRING,
      isTrnsMsgAllowed: EMPTY_STRING,
      isMktgMsgAllowed: EMPTY_STRING,
    },
  ],
  consentInfo: [
    {
      phoneNumber: EMPTY_STRING,
      consentType: EMPTY_STRING,
      consent: EMPTY_STRING,
      lineOfBusiness: EMPTY_STRING,
      consentDate: EMPTY_STRING,
    },
  ],
  customerStatus: {
    customerSinceDate: EMPTY_STRING,
    createdBy: EMPTY_STRING,
    lastModifiedBy: EMPTY_STRING,
    createdDate: EMPTY_STRING,
    lastModifiedDate: EMPTY_STRING,
    createdSource: EMPTY_STRING,
    lastModifiedSource: EMPTY_STRING,
  },
};

export const INTELLICHECK_DETAILS_TEMPLATE = {
  first_name: EMPTY_STRING,
  last_name: EMPTY_STRING,
  dob: EMPTY_STRING,
  address1: EMPTY_STRING,
  address2: EMPTY_STRING,
  locality: EMPTY_STRING,
  province_code: EMPTY_STRING,
  postal_code: EMPTY_STRING,
  country_code: EMPTY_STRING,
  name_score: null,
  location_score: null,
  id_score: null,
  id_document: {
    type: EMPTY_STRING,
    number: EMPTY_STRING,
    province_code: EMPTY_STRING,
  },
  matches: {
    first_name: false,
    last_name: false,
    dob: false,
  },
  images: {
    front: EMPTY_STRING,
    selfie: EMPTY_STRING,
  },
}

export const EMPLOYMENT_TAB_DOCUMENTS_LIST = [
  {
    type: 'P',
    value: 'Recent Pay Stub'
  },
  {
    type: 'P',
    value: 'Bank Statement'
  },
  {
    type: 'P',
    value: 'Social Security Benefits Statement'
  },
  {
    type: 'S',
    value: 'Alimony'
  },
  {
    type: 'S',
    value: 'Any Other Document with Proof of Income'
  }
]



export const RESIDENCE_TAB_DOCUMENT_LIST = [
  {
    type: 'P',
    value: 'Utility bill (electricity, gas, garbage, water, or sewer)'
  },
  {
    type: 'P',
    value: 'Cable TV or internet bill'
  },
  {
    type: 'P',
    value: 'Government letters'
  },
  {
    type: 'S',
    value: 'Bank statement'
  },
  {
    type: 'S',
    value: 'Property tax bill'
  },
  {
    type: 'S',
    value: 'Mortgage statement'
  },
  {
    type: 'S',
    value: 'Insurance Documents'
  }, {
    type: 'S',
    value: 'School Records'
  },
  {
    type: 'S',
    value: 'Tax Documents'
  }
]

export const APPLICATION_PDF = 'application/pdf';

export const SOMETHING_WENT_WRONG = 'Something went wrong';

export const BORDER_BOTTOM_STYLE = '1px solid #000'


export const familyReferenceRefcode = ['SPO', 'SIBLING', 'PARENT', 'CHILD']
export const nonFamilyReferenceRefcode = ['FRI', 'CWR', 'LND', 'OTH']

export const SUPPORTED_FILE_TYPE = ['image/png', 'image/jpeg', 'application/pdf'];

export const LEVEL_ONE_APPROVAL = ['ID', 'RESIDENCE'];
export const LEVEL_TWO_APPROVAL = ['ID', 'RESIDENCE', 'EMPLOYMENT'];
export const LEVEL_THREE_APPROVAL = ['ID', 'RESIDENCE', 'EMPLOYMENT', 'REFERENCES'];
export const LEVEL_FOUR_APPROVAL = ['ID', 'RESIDENCE', 'EMPLOYMENT', 'REFERENCES'];
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import { ReactComponent as CameraIcon } from "./../../../images/cameraIcon.svg";
import { jsPDF } from "jspdf";
import { Grid, RACButton } from "@rentacenter/racstrap";
import { VerificationStyles } from "../../../styles/verificationStyles";

const DocumentCapture = (updateDoc: any, setDocSize: any, setDocType: any) => {
    const [imageDoc, setImageDoc] = useState<any>(null);
  const classes: any = VerificationStyles();
  const [openDocScan, setOpenDocScan] = useState<boolean>(false);
  const [capturedImage, setCapturedImage] = useState<any>(null);
  const [showResidenceVerfDocument, setShowResidenceVerfDocument] = useState<any>(false);

  const [deviceAvailable, setDeviceAvailable] = useState<any>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [videoConstraints, setVideoConstraints] = useState<any>({
    facingMode: "environment",
    width: { ideal: 1280 },
    height: { ideal: 720 },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [videoDevices, setVideoDevices] = useState<any>([]);
  const [currentDeviceId, setCurrentDeviceId] = useState<any>(null);

  const webcamRef = useRef<any>(null);
  const redAreaRef = useRef<any>(null);

  const [isMobile, setIsMobile] = useState(false);

  // Check if the device is mobile or not
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isMobileDevice = /android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent) || window.innerWidth <= 768;
    if (isMobileDevice) {
      console.log('Inside the mobile device', isMobile);
      setIsMobile(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCamera = () => {
    setOpenDocScan(true);
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoDevices = devices.filter(
            (device) => device.kind === "videoinput"
          );
          if (videoDevices.length === 0) {
            setDeviceAvailable(false);
            alert("No camera found on your device");
          } else {
            setVideoDevices(videoDevices);
            if (isMobile) {
              // If it's a mobile device, select the rear camera (environment)
              const rearCamera = videoDevices.find((device: any) => device.label.toLowerCase().includes("back"));
              console.log('Rear camera', rearCamera);
              setCurrentDeviceId(rearCamera?.deviceId || videoDevices[0].deviceId); // Default to first device if rear not found
            } else {
              // If it's a desktop or laptop, select the 0th video device
              setCurrentDeviceId(videoDevices[0].deviceId);
            }
          }
        })
        .catch((error) => {
          console.error("Error accessing devices:", error);
          setDeviceAvailable(false);
          alert("Error accessing camera");
        });
    }
  };

//   const flipCamera = () => {
//     const nextDevice = videoDevices.find(
//       (device: any) => device.deviceId !== currentDeviceId
//     );
//     if (nextDevice) {
//       setCurrentDeviceId(nextDevice.deviceId);
//       setVideoConstraints({
//         ...videoConstraints,
//         deviceId: nextDevice.deviceId,
//       });
//     }
//   };

  // Capture the image from the webcam
  const handleCapture = (imageData: any) => {
    if (imageData) {
      setCapturedImage(imageData);
      setDocSize(`${(imageData.length / 1024).toFixed(2)} KB`);
      setDocType("image/png");
      setShowResidenceVerfDocument(true);
  
      // Get the red area (square overlay) bounding box coordinates
      const squareOverlay = redAreaRef.current;
      const squareBounds = squareOverlay.getBoundingClientRect();
  
      // Get the position and size of the video element
      const videoElement = webcamRef.current.video;
      const videoBounds = videoElement.getBoundingClientRect();
  
      // Calculate the offset of the squareOverlay within the video element (considering scaling)
      const offsetX = squareBounds.left - videoBounds.left;
      const offsetY = squareBounds.top - videoBounds.top;
  
      // Create a new image and load the captured image into it
      const img = new Image();
      img.src = imageData;
  
      img.onload = () => {
        // Create a canvas to crop the image
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        // Set the canvas size to match the dimensions of the square overlay
        canvas.width = squareBounds.width;
        canvas.height = squareBounds.height;
  
        // Crop the image based on the square overlay position inside the video
        ctx?.drawImage(
          img,
          offsetX, // Start at the adjusted X position inside the video element
          offsetY, // Start at the adjusted Y position inside the video element
          squareBounds.width, // Width of the square (same as canvas)
          squareBounds.height, // Height of the square (same as canvas)
          0, // Start at the top-left corner of the canvas
          0, // Start at the top-left corner of the canvas
          squareBounds.width, // Canvas width
          squareBounds.height // Canvas height
        );
  
        // Convert the canvas content into a data URL (image format)
        const croppedImage = canvas.toDataURL("image/png");
        setCapturedImage(croppedImage);
  
        // Generate PDF if needed (commented out if you don't need this step)
        const pdf = new jsPDF();
        pdf.addImage(croppedImage, "PNG", 10, 10, 180, 180);
        const pdfBlob = pdf.output("blob");
        setImageDoc(pdfBlob)
      };
    } else {
      console.error("No image data captured.");
    }
  };

  const resetCapture = () => {
    setCapturedImage(null);
    setShowResidenceVerfDocument(false);
  };

  const closeCamera = () => {
    setCapturedImage(null);
    setShowResidenceVerfDocument(false);
    setOpenDocScan(false);
  };

  const confirmCapture = () => {
    setOpenDocScan(false);
    setCapturedImage(null);
    setShowResidenceVerfDocument(false);
    updateDoc(imageDoc);
  };

  return (
    <Grid style={{ width: "100%" }}>
      <RACButton
        variant="outlined"
        color="primary"
        onClick={openCamera}
        startIcon={<CameraIcon />}
        className={`${classes.mb15px} ${classes.buttonBorderColor} ${classes.blackFont} ${classes.font16px} ${classes.w91p}`}
      >
        OPEN CAMERA AND TAKE PHOTO
      </RACButton>

      {openDocScan && deviceAvailable && !showResidenceVerfDocument && (
        <Grid
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <Grid
            style={{
              position: "relative",
              width: "90%",
              maxWidth: "800px", // Limit max size for large screens
              height: "80%",
              borderRadius: "10px",
              backgroundColor: "black",
              padding: "20px",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/png"
              width="100%"
              height="100%"
              videoConstraints={{
                ...videoConstraints,
                deviceId: currentDeviceId,
              }}
              style={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
            />

            {/* Red Area for Document Capture (A4 Size, Corrected Aspect Ratio) */}
            <Grid
              ref={redAreaRef}
              style={{
                position: "absolute",
                top: "10%", // Adjusts the red area to stay within the visible bounds of the parent Grid
                left: "50%",
                transform: "translateX(-50%)", // Centers the red area horizontally
                width: "80%", // Red area width as a percentage of the webcam container's width
                height: "80%", // Height adjusts based on the width to maintain aspect ratio
                border: "2px solid red", // Red border
                pointerEvents: "none", // Ensures the red area doesn't interfere with the webcam
                maxWidth: "100%", // Ensures red area doesn't go beyond the container
                maxHeight: "80%", // Limits the height to ensure it fits within the parent container
              }}
            />

            {/* Overlay message */}
            <Grid
              style={{
                position: "absolute",
                top: "5%",
                left: "50%",
                transform: "translateX(-50%)",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                padding: "10px",
                borderRadius: "5px",
                maxWidth: '300px'
              }}
            >
              Capture the document within the red area
            </Grid>

            {/* Buttons */}
            <Grid
              style={{
                position: "absolute",
                bottom: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginLeft: '-20px'
              }}
            >
              <RACButton
                variant="contained"
                color="primary"
                onClick={() => handleCapture(webcamRef.current.getScreenshot())}
              >
                Capture Photo
              </RACButton>
            </Grid>

            {/* Close button */}
            <Grid
              style={{
                position: "absolute",
                top: "5%",
                right: "5%",
                cursor: "pointer",
                fontSize: "24px",
                color: "#fff",
              }}
              onClick={closeCamera}
            >
              ✕
            </Grid>
          </Grid>
        </Grid>
      )}

      {openDocScan && showResidenceVerfDocument && capturedImage && (
        <Grid
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <Grid
            style={{
              position: "relative",
              width: "90%",
              maxWidth: "800px",
              borderRadius: "10px",
              backgroundColor: "black",
              padding: "20px",
              textAlign: "center",
              height: "70%",
              maxHeight: "600px",
            }}
          >
            <img
              src={capturedImage}
              alt="Captured"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                objectFit: "contain", // Ensures the image fits within the red area
                maxHeight: "100%", // Allow image to scale properly on mobile
                maxWidth: "100%", // Ensure image doesn't overflow
              }}
            />

            <Grid
              style={{
                position: "absolute",
                bottom: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "30px",
                marginLeft: '-20px'
              }}
            >
              <RACButton
                variant="contained"
                color="secondary"
                onClick={resetCapture}
              >
                Retake
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                onClick={confirmCapture}
              >
                Confirm
              </RACButton>
            </Grid>

            <Grid
              style={{
                position: "absolute",
                top: "5%",
                right: "5%",
                cursor: "pointer",
                fontSize: "24px",
                color: "#fff",
              }}
              onClick={closeCamera}
            >
              ✕
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DocumentCapture;

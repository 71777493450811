/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/cognitive-complexity */

import { Grid, RACButton, Typography } from "@rentacenter/racstrap";
import React, { useEffect, useState, useRef, useContext } from "react";
import { generateDOFAccessLink, GetApproval, sendIntellicheckVerificationLink } from "../../../api/user";
import { useHistory, useParams } from "react-router-dom";
import ScreenLoader from "../Common/ScreenLoader";
import { VerificationStyles } from "../../../styles/verificationStyles";
import { DigitalOrderFormContext } from "../Context/DigitialOrderFormContext";
import {
  getCustomerDetails,
  getIntellicheckDetails,
} from "../Common/GetCustomerDetails";
import {
  Address,
  CustomerInfo,
  IntellicheckCustomerDetails,
} from "../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel";
import {
  BUSINESS_UNIT,
  EMPTY_STRING,
  INTELLICHECK_DETAILS_TEMPLATE,
  SOMETHING_WENT_WRONG,
  UNITED_STATES,
} from "../../../constants/constants";
import {
  AddressType,
  BooleanRepresentation,
  GovernmentIdType,
  IntellicheckGovtIDType,
  Status,
} from "../../../constants/enums";
import {
  getIdDocumentType,
  getNextNavigator,
  updateCustomerApproval,
} from "../Common/HelperFunctions";
import SomethingWentWrongPopUp from "../Common/SomethingWentWrong";
import MessagePopup from "../Common/MessagePopup";
import { ReferenceTabContext } from "../Context/ReferenceDetailsContext";
import { ResidenceTabContext } from "../Context/ResidenceDetailsContext";
import { IncomeTabContext } from "../Context/IncomeDetailsContext";

export const IdentityTab = () => {
  const classes: any = VerificationStyles();
  const { decisionEngineDetails, customerDetails, setCVOCustomerDetails, setIntellicheckVerfDetails, setShowIdentityDetails, setIsIdentityDetailsFetched, isIdentityDetailsFetched, setDecisionEngineDetails, intellicheckPassScore, dynamicHeader } =
    useContext(DigitalOrderFormContext);

  const { employementDetails, setEmployementDetails } = useContext(IncomeTabContext)

  const {
    setFamilyReferenceDetails,
    setnonFamilyReferenceDetails,
    nonFamilyReferenceDetails,
    familyReferenceDetails
  } = useContext(ReferenceTabContext);
  const { landlordResidence,
    setLandlordResidence } = useContext(ResidenceTabContext);
  // PS_07: useState for iframe URL
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [verificationComplete, setVerificationComplete] =
    useState<boolean>(false);
  const iframeRef = useRef<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const paramValue = queryParams.get("status");
  const getDetails = queryParams.get("get");
  const { customerId, approvalId } = useParams<any>();
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  //handling the style
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Conditional rendering based on screen width
  const isMobile = screenWidth <= 480;
  const isTablet = screenWidth > 480 && screenWidth <= 1037;
  const isDesktop = screenWidth > 1037;

  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;

  useEffect(() => {
    setLoader(true);
    console.log('Loader', loader);
    fetchAndSendIntellicheckLink(customerId);
  }, [paramValue]);

  useEffect(() => {
    const checkDetails = async () => {
      if (getDetails && getDetails == 'details') {
        await checkVerificationCompleted()
      }
    }

    checkDetails()
  }, [])

  useEffect(() => {
    // Call sendHeightToParent initially to send the height on mount
    sendHeightToParent();
    // Add an event listener for window resizing
    const handleResize = () => {
      sendHeightToParent();
    };
    window.addEventListener("resize", handleResize);
  }, [loader]);

  // Adding the Google Analytics tags

  useEffect(() => {
    window.dataLayer?.push({
      'event': 'Loaded the DOF Idenity Verification Page',
      'storeNo': sessionStorageValues?.storeNumber,
      'approvalId': sessionStorageValues?.approvalId,
      'currentScreen': 'DOF Idenity Verification Screen',
      'previousScreen': 'DOF Starting Screen',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendHeightToParent = () => {
    if (!loader && !error) {
      const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
      const iframeWidth = document.body.scrollWidth;
      console.log('Inside the Iframe Message send', iframeHeight, iframeHeight < 740 ? 740 : iframeHeight < 1450 ? iframeHeight : 1450)
      window.parent.postMessage(
        {
          message: "racpad-styling",
          minHeight: iframeHeight < 740 ? 740 : iframeHeight < 1450 ? iframeHeight : 1450,
          currentHeight: iframeHeight,
          iframeWidth: iframeWidth,
          verificationProcessCompleted: false
        },
        "*"
      );
    }
  };

  console.log("Loader", loader);

  // Main function to fetch customer details and send Intellicheck link
  const fetchAndSendIntellicheckLink = async (customerId: string) => {
    try {
      if (!customerDetails?.personalInfo?.customerId) {
        const customerData: CustomerInfo = await getCustomerDetails(
          customerId,
          setCVOCustomerDetails,
          landlordResidence,
          setLandlordResidence,
          familyReferenceDetails,
          setFamilyReferenceDetails,
          nonFamilyReferenceDetails,
          setnonFamilyReferenceDetails,
          employementDetails, setEmployementDetails
        );
        sendIntellicheckLink(customerData);
      } else {
        sendIntellicheckLink(customerDetails);
      }
    } catch (error: any) {
      console.log("Error in Fetch and Send Intellicheck Link service", error);
      setLoader(false);
      setErrorMessage(SOMETHING_WENT_WRONG);
      setError(true);
    }
  };

  // Function to create the payload for sending Intellicheck link
  const createPayload = (customerDetails: CustomerInfo) => {
    const primaryAddress = getPrimaryAddress(customerDetails);
    // const idDocumentType = getIdDocumentType(customerDetails);
    const featureFlagDetails: any = window.sessionStorage.getItem('featureFlagDetails')
    console.log('featureflag details', featureFlagDetails);
    let documentSides: any = ''
    const authDetails: any = JSON.parse(window.sessionStorage.getItem("dofAuthValues") || '');
    if (featureFlagDetails) {
      documentSides = JSON.parse(featureFlagDetails);
      documentSides = JSON.parse(documentSides?.find((ele: any) => ele.paramKeyName == 'IntellicheckDocumentSides')?.paramValue?.slice(1, -1))?.values;
      console.log('DE Details', decisionEngineDetails);
      if(decisionEngineDetails && decisionEngineDetails?.verifyLevel) {
        console.log('Verification Level', decisionEngineDetails?.verifyLevel, JSON.parse(featureFlagDetails)?.find((ele: any) => ele.paramKeyName === 'CustomerSourceVerificationLevels'))
        const selfieRequiredCustomerSource = featureFlagDetails ? JSON.parse(JSON.parse(featureFlagDetails)?.find((ele: any) => ele.paramKeyName === 'CustomerSourceVerificationLevels')?.paramValue?.slice(1, -1) || 'null') : null;
        const selfieRequiredVerificationDocuments = featureFlagDetails ? JSON.parse(JSON.parse(featureFlagDetails)?.find((ele: any) => ele.paramKeyName === 'RequiredDocumentsVerificationLevels')?.paramValue?.slice(1, -1) || 'null') : null;
        // console.log('Selfie Levels', selfieLevels, documentSides);
        // if(selfieLevels && !selfieLevels?.includes(Number(decisionEngineDetails?.verifyLevel))) {
        //   documentSides = documentSides?.filter((element: any) => element != 'selfie');
        //   console.log('Selfie removed document sides', documentSides);
        // }
        const requiredCustomerSource = selfieRequiredCustomerSource?.find((element: any) => element.lvl == decisionEngineDetails?.verifyLevel)?.custSrc;
        const requiredCustomerDocuments = selfieRequiredVerificationDocuments?.find((element: any) => element.lvl == decisionEngineDetails?.verifyLevel)?.reqDoc;
        const salesLeadId = customerDetails.personalInfo.salesLeadId;
        const verifyCode = decisionEngineDetails?.verifyCode;
        console.log('requiredCustomerSource, requiredCustomerDocuments', requiredCustomerSource, requiredCustomerDocuments)
        let customerSource: any = '';
        let requiredDocuments : any = [];
          if ((salesLeadId || (atob(sessionStorageValues?.clientId) == 'EC' || atob(sessionStorageValues?.clientId) == 'ECOM' || customerDetails.personalInfo.createdBy == 'EC' || customerDetails.personalInfo.createdBy == 'ECOM')) && (verifyCode == "-1" || verifyCode == null)) {
            customerSource = "Prev-Web";
          } else if ((salesLeadId || (atob(sessionStorageValues?.clientId) == 'EC' || atob(sessionStorageValues?.clientId) == 'ECOM' || customerDetails.personalInfo.createdBy == 'EC' || customerDetails.personalInfo.createdBy == 'ECOM')) && (verifyCode == "0" || verifyCode == "1")) {
            customerSource = "New-Web";
          } else if (verifyCode == "-1" || verifyCode == null) {
            customerSource = "Prev";
          } else if (verifyCode == "0" || verifyCode == "1") {
            customerSource = "New";
          }
          console.log('Customer Source', customerSource);
          if(requiredCustomerDocuments && requiredCustomerDocuments?.length) {
            if(requiredCustomerSource && requiredCustomerSource?.length) {
              if(!requiredCustomerSource.includes(customerSource)) {
                requiredDocuments = requiredCustomerDocuments?.filter((ele: any) => ele != 'selfie')
              } else {
                requiredDocuments = requiredCustomerDocuments
              }
            } else {
              requiredDocuments = requiredCustomerDocuments
            }
          } else {
            requiredDocuments = ['front'];
          }
        
        documentSides = requiredDocuments;

        console.log('Customer Source', customerSource);
      }
      console.log('document sides', documentSides);
    } else {
      documentSides = ['front']
    }
    console.log('Authkey details', authDetails?.encodeAuthKey)

    let url = `${origin}/?AuthKey=${authDetails?.encodeAuthKey}&userId=${authDetails?.userId}&StoreCode=${authDetails?.encodeStoreNumber}&deId=${authDetails?.encodedApprovalId}&clientId=${authDetails?.clientId}&customerId=${customerId}&navpage=idcompleted`
    if (authDetails?.encodedSalesLeadId) {
      url = `${origin}/?AuthKey=${authDetails?.encodeAuthKey}&userId=${authDetails?.userId}&StoreCode=${authDetails?.encodeStoreNumber}&deId=${authDetails?.encodedApprovalId}&clientId=${authDetails?.clientId}&salesLeadId=${authDetails?.encodedSalesLeadId}&customerId=${customerId}&navpage=idcompleted`
    }
    return {
      redirectUrl: url,
      globalCustomerId: `cust-${approvalId}`,
      businessUnit: BUSINESS_UNIT,
      dateOfBirth: customerDetails.personalInfo.dateOfBirthDec,
      firstName: customerDetails.personalInfo.firstName,
      lastName: customerDetails.personalInfo.lastName,
      addressLine1: primaryAddress?.addressLine1,
      city: primaryAddress?.city,
      provinceCode: primaryAddress?.state,
      postalCode: primaryAddress?.postalCode,
      countryCode: UNITED_STATES,
      requestedDocumentSides: documentSides,
    };
  };

  // Function to fetch the primary address from the customer details
  const getPrimaryAddress = (customerDetails: CustomerInfo) => {
    return customerDetails.addresses.find(
      (address: Address) =>
        address.addressType === AddressType.Primary &&
        address.active === BooleanRepresentation.Y
    );
  };

  // Function to send the Intellicheck link with the payload
  const sendIntellicheckLink = async (customerDetails: CustomerInfo) => {
    const payload = createPayload(customerDetails);
    console.log("Inside the SendIntellicheck Details", payload);
    if (paramValue && paramValue === Status.completed) {
      console.log("Inside Completed Param value", payload);
      setVerificationComplete(true);
      setIframeUrl("");
      setLoader(false);
      return;
    }

    try {
      const response = await sendIntellicheckVerificationLink(payload, isStoreCodeFlow, accessToken, 'RACPAD');
      console.log("Response from SendIntellicheck Service", response);
      if (response.status == 200) {
        setLoader(false);
        if (response.data?.value?.code == 500) {
          console.log("Sucesses Response from Intellicheck", response?.data);
          setError(true);
          setErrorMessage(
            "Intellicheck verification failed. Please try again later."
          );
        } else {
          console.log("Sucesses Response from Intellicheck", response?.data);
          setIframeUrl(response?.data?.value?.capture_url);
        }
      } else {
        console.log("Error In send Intellicheck");
        setError(true);
        setErrorMessage(SOMETHING_WENT_WRONG);
        setLoader(false);
      }
    } catch (error: any) {
      console.log("Error in SendIntellicheck Service Call", error);
      setError(true);
      setErrorMessage(SOMETHING_WENT_WRONG);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  // PS_28: Handle iframe load and redirect detection
  const handleIframeLoad = () => {
    // eslint-disable-next-line no-console
    console.log(
      "Iframe Reference details",
      iframeRef,
      iframeRef.current?.contentWindow?.location.href?.includes(
        window.location.origin
      )
    );

    if (
      iframeRef.current?.contentWindow?.location.href?.includes(
        window.location.origin
      )
    ) {
      // eslint-disable-next-line no-console
      console.log(
        "Inside the completed Iframe",
        iframeRef.current?.contentWindow?.location.href
      );
      handleVerificationComplete();
    }
  };

  // PS_29: Handle verification complete
  const handleVerificationComplete = async () => {
    try {
      setLoader(true);
      // eslint-disable-next-line no-console
      console.log("Inside the handleVerificationComplete");
      setIframeUrl(null);
      setVerificationComplete(true);
      history.push(`/identity/${customerId}/${approvalId}?status=completed`);
      const fetchIntellicheckVerificationDetails:
        | IntellicheckCustomerDetails
        | string = await getIntellicheckDetails(
          approvalId,
          setIntellicheckVerfDetails,
          customerId,
          intellicheckPassScore
        );
      if (fetchIntellicheckVerificationDetails !== "incomplete")
        setIntellicheckVerfDetails(fetchIntellicheckVerificationDetails);
      setLoader(false);
    } catch (error: any) {
      console.log("Error in Fetch Intellicheck Details Service", error);
      setLoader(false);
      setIntellicheckVerfDetails(INTELLICHECK_DETAILS_TEMPLATE);
    }
  };

  // PS_31: Handle next button click
  const handleNext = () => {
    setShowIdentityDetails(true)
    history.push(`/${getNextNavigator('Identity', dynamicHeader)}/${customerId}/${approvalId}`);
    // updateCustomerApproval("ID", approvalId, customerId);
  };

  // Function to update screen width on window resize
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Set up an event listener for window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);




  const checkVerificationCompleted = async () => {
    setLoader(true);
    try {
      const fetchIntellicheckVerificationDetails:
        any = await getIntellicheckDetails(
          approvalId,
          setIntellicheckVerfDetails,
          customerId,
          intellicheckPassScore
        );
      if (fetchIntellicheckVerificationDetails == "incomplete") {
        setError(true);
        setErrorMessage(
          "It looks like your Identity Verification is incomplete. Once submited. you can proceed to the next step."
        );
      } else if (fetchIntellicheckVerificationDetails && fetchIntellicheckVerificationDetails?.first_name != '') {
        setIntellicheckVerfDetails(fetchIntellicheckVerificationDetails);
        setIsIdentityDetailsFetched(true)
        history.push(`/identity/${customerId}/${approvalId}?status=completed`);
      } else {
        if (isDesktop) {
          handleManageApproval()
        } else {
          handleNext();
        }
      }
    } catch (e: any) {
      console.log("Error in checkVerificationCompleted function", e);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleManageApproval = async () => {
    setLoader(true);
    const request: any = {
      decisionId: approvalId
    }
    const getApprovalServiceResponse: any = await GetApproval(request, sessionStorageValues?.authKey ? false : sessionStorageValues?.storeNumber,
      sessionStorageValues?.authKey ? sessionStorageValues?.authKey : sessionStorageValues?.storeNumber);

    console.log('Get Approval Service Response', getApprovalServiceResponse);
    if (getApprovalServiceResponse && getApprovalServiceResponse?.globalCustomerId) {
      setDecisionEngineDetails(getApprovalServiceResponse)
      setLoader(false);
      const isContinueFlow: any = getApprovalServiceResponse?.verificationDocuments?.find((element: any) => element.verificationStatus == 'PEND');
      if (isContinueFlow) {
        history.push(`/identity/${customerId}/${approvalId}?status=completed`);
      } else {
        window.parent.postMessage(
          {
            status: 200,
            message: "DOF Verification Completed",
            verificationProcessCompleted: true
          },
          "*"
        );
        if (atob(sessionStorageValues?.clientId) != 'EC' && atob(sessionStorageValues?.clientId) != 'ECOM') {
          history.push(`/dof/submission/${customerId}/${approvalId}`);
        }
      }
    }
  }

  return (
    <Grid>

      {error ? (
        <MessagePopup
          value={{
            message: errorMessage,
            setFunction: setError,
            cleanupFunction: setErrorMessage,
          }}
        />
      ) : null}
      {loader ? <ScreenLoader /> : null}
      <Grid
        className={
          isMobile
            ? `${classes.mobileFrame}`
            : isTablet
              ? `${classes.tabFrame}`
              : `${classes.desktopFrame}`
        }
        style={{ width: "100%", height: "80vh" }}
      >
        {iframeUrl && (
          <Grid className={`${classes.iframeContainer}`}>
            <iframe
              src={iframeUrl}
              onLoad={handleIframeLoad}
              ref={iframeRef}
              title="Identity Verification"
              className={
                isMobile
                  ? `${classes.iframeMobile}`
                  : isTablet
                    ? `${classes.iframeTab}`
                    : `${classes.iframeDesktop}`
              }
              allow={"camera"}
              frameBorder={"0"}
              id="iframe"
            />
            {isDesktop && (
              <Grid>
                <Typography className={`${classes.font14px}`}>Once you❜ve completed the Identity Validation process on you phone, please click ❛Continue❜ below.</Typography>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => checkVerificationCompleted()}
                  className={`${classes.mb15px} ${classes.mt20px} ${classes.font16px} ${classes.primaryButtonColor} ${classes.w15p}`}
                >
                  Continue
                </RACButton>
              </Grid>
            )}
          </Grid>
        )}
        {verificationComplete && (
          <Grid>
            <Typography
              className={`${classes.tittleColor} ${classes.boldFont}`}
            >
              Government ID Submitted
            </Typography>
            <Grid
              className={
                isMobile
                  ? `${classes.p16px} ${classes.textAlignLeft} ${classes.pt0px}`
                  : isTablet
                    ? `${classes.tabGridStyle}`
                    : `${classes.desktopGridStyle}`
              }
            >
              <Typography
                className={`${classes.regularFont} ${classes.blackFont} ${classes.mt20px}`}
              >
                Your government identity validation is complete. Please proceed
                to the next step to continue your verification.
              </Typography>
              <RACButton
                color="primary"
                variant="contained"
                className={
                  isMobile
                    ? `${classes.primaryButtonColor} ${classes.width100p} ${classes.mt20px}`
                    : isTablet
                      ? `${classes.primaryButtonColor} ${classes.tabButtonSize}`
                      : `${classes.primaryButtonColor} ${classes.desktopButtonSize}`
                }
                onClick={async () => {
                  handleNext();
                }}
              >
                Next
              </RACButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

/* eslint-disable prettier/prettier */
/* eslint-disable no-console */

import React, { useState } from 'react';
// import jsPDF from 'jspdf';
import { Grid, Typography } from '@rentacenter/racstrap';
import { convertImageToPDF, convertPDFToBase64 } from './HelperFunctions';

const FileToPDFViewer = ({ file } : any) => {
  const [pdfBase64, setPdfBase64] = useState<string>('');

  // Handle file processing based on the type
  const handleFile = async () => {
    const fileType = file.type;
    // eslint-disable-next-line no-console
    console.log('File Type', fileType, file)
    if (fileType === "application/pdf") {
      // If it's a PDF, convert it to Base64
      const pdfData: any = await convertPDFToBase64(file);
      console.log('Base64 URL PDF', pdfData)
      setPdfBase64(pdfData?.url);
    } else if (fileType && fileType.startsWith("image/")) {
      // If it's an image (JPEG, PNG), convert it to Base64 and then to PDF
      const reader: any = new FileReader();
      reader.onloadend = async () => {
        const imageB64String: any = await convertImageToPDF(reader.result);
        const base64Pdf: any = await convertPDFToBase64(imageB64String);
        console.log('Base64 URL IMAGE', base64Pdf)
        setPdfBase64(base64Pdf?.url);
      };
      reader.readAsDataURL(file); // Convert image to Base64
    } else if (
      !fileType &&
      file?.split(",")[0]?.includes("data:image/jpeg;base64")
    ) {
      const imageB64String: any = await convertImageToPDF(file?.split(",")[1]);
      const base64Pdf: any = await convertPDFToBase64(imageB64String);
      console.log('Base64 URL', base64Pdf)
      setPdfBase64(base64Pdf?.url);
    }
  };

  // Call handleFile once the file is selected
  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('file in FiletoPdfViewer', file);
    if (file) {
      handleFile();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Grid>
      {pdfBase64 ? (
        <iframe
          src={`${pdfBase64}#toolbar=0`}
          width="100%"
          height="100%"
          frameBorder={0}
        />
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Grid>
  );
};

export default FileToPDFViewer;
/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
export const IncomeTabContext = createContext<any>('');
export function IncomeTabContextProvider(props: any) {
    const [IdentityTab, setIdentityTab] = useState(false)
    const [manualEntryEnabled, setManualEntryEnabled] = useState(false);
    const [employementDetails, setEmployementDetails] = useState({
        employerName: '',
        employerPhoneNumber: '',
        formatedMobileNumber: '',
        employerReferenceId: ''
    })
    const [employementDetailsCopy, setEmployementDetailsCopy] = useState({
        employerName: '',
        employerPhoneNumber: '',
        formatedMobileNumber: '',
        employerReferenceId: ''
    })



    return (
        <div>
            <IncomeTabContext.Provider
                value={{
                    IdentityTab, setIdentityTab,
                    manualEntryEnabled, setManualEntryEnabled,
                    employementDetails, setEmployementDetails,
                    employementDetailsCopy, setEmployementDetailsCopy
                }}
            >
                {props.children}
            </IncomeTabContext.Provider>
        </div>
    );
}

/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect, useContext } from 'react';
import { dofContextValue } from '../context/DofContext';
import { Validator as valid } from '../utils/utils';
import {
  RACCard,
  RACButton,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@rentacenter/racstrap';

import timer from '../images/Timer.svg';
import { customerDetails as CUSTDTO } from '../interfaces/interfaces';
import DofHeaders from './DofHeaders';
import { DofCssClasses } from '../styles/dofStyles';
import { validateAuthKey } from '../api/user';
import { useHistory } from 'react-router-dom';
import { LoaderPopup } from './LoderPopup';
import { DigitalOrderFormContext } from './CustomerVerification/Context/DigitialOrderFormContext';
export default function DofStartingPage() {
  const classes: any = DofCssClasses();
  let { customerDetails, setCustomerDetails, isEmailVerified,
    setIsEmailVerified } = useContext(dofContextValue);

  const { setexistingFlow } = useContext(DigitalOrderFormContext)

  const history = useHistory();
  const [IsLookingUpOpen, setIsLookingUpOpen] = useState(false);
  const dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const EncodeAuthKey = params.get('AuthKey');
    const EncodedStorecode = params.get('StoreCode') || '';
    const EncodeUserId = params.get('userId') || '';
    type phoneValidator = Array<string | boolean>;


    console.log(atob(EncodedStorecode), 'this value');

    const SSNDisabledValue: boolean =
      params.get('mandatory')?.toLowerCase() == 'true';
    console.log(EncodeAuthKey, 'auth');



    customerDetails = {
      ...customerDetails, authKey: EncodeAuthKey != '' ? EncodeAuthKey : '',
      storeCode: EncodedStorecode != '' ? atob(EncodedStorecode) : '',
      authFlow: EncodeAuthKey != '' ? true : false,
      EncodedStorecode: EncodedStorecode != '' ? EncodedStorecode : '',
      EncodeAuthKey: EncodeAuthKey != '' ? EncodeAuthKey : '',

    }

    if (EncodedStorecode) {
      sessionStorage.setItem("storeNumber", atob(EncodedStorecode));
    }
    if (EncodeUserId) {
      sessionStorage.setItem("encodeUserId", atob(EncodeUserId));

    }

    setCustomerDetails(customerDetails)
    console.log(SSNDisabledValue, 'ssn');
    ValidateAuthKeyValue(EncodeAuthKey, EncodedStorecode);
  }, []);


  function handleButton() {

    if (sessionStorageValues?.dofFeatureFlag == '1') {
      setexistingFlow(false)
      history.push('/otp/auth')
    }
    else {
      history.push('/userinfo');
    }

  }
  // console.log('stroeNumber', customerDetails.storeCode);
  const ValidateAuthKeyValue = async (EncodeAuthKey: string | null, EncodedStorecode: string | null) => {
    setIsLookingUpOpen(true);
    if (EncodeAuthKey) {
      console.log('flow of auth');

      let ResponseValidateAuth: any = await validateAuthKey(EncodeAuthKey, false);
      ResponseValidateAuth = ResponseValidateAuth?.data
      console.log(ResponseValidateAuth, 'response');
      if (
        ResponseValidateAuth &&
        ResponseValidateAuth?.valid === true &&
        ResponseValidateAuth?.status === 'A'
      ) {
        sessionStorage.setItem("storeNumber", (ResponseValidateAuth?.storeNumber));
        customerDetails = {
          ...customerDetails, mobileNumber: ResponseValidateAuth.phoneNumber
            ? valid(
              ResponseValidateAuth.phoneNumber,
              'phoneNumber'
            )[2]
            : '',
          email: ResponseValidateAuth.emailAddress
            ? ResponseValidateAuth.emailAddress.toLowerCase()
            : '',
          authFlowEmail: ResponseValidateAuth.emailAddress
            ? ResponseValidateAuth.emailAddress.toLowerCase()
            : '',
          authFlowPhone: ResponseValidateAuth.phoneNumber ? ResponseValidateAuth.phoneNumber : '',
          unFormattedPhoneNumber:
            ResponseValidateAuth.phoneNumber,
          storeNumber: ResponseValidateAuth.storeNumber
            ? ResponseValidateAuth.storeNumber
            : '',
        }
        setIsEmailVerified(valid(
          ResponseValidateAuth.emailAddress,
          'email'
        )[1] ? true : false)
        setCustomerDetails(customerDetails)
      } else if (
        ResponseValidateAuth?.valid !== undefined &&
        ResponseValidateAuth?.status !== undefined &&
        ResponseValidateAuth?.valid === true &&
        ResponseValidateAuth?.status === 'C'
      ) {
        history.push('/IdentityVerify?status=Completed')
      } else if (
        ResponseValidateAuth?.valid !== undefined &&
        !ResponseValidateAuth?.valid
      ) {
        history.push('/IdentityVerify?status=Expired');
      }
      else {
        history.push('/IdentityVerify?status=Expired');
      }
      console.log(customerDetails, 'customer');
    } else if (EncodedStorecode) {
      console.log('storeCode flow');
      const DecodeStoreCode = new Buffer(EncodedStorecode, 'base64');
      let DecodedNum = DecodeStoreCode.toString('ascii');
      const ValidateStore = await validateAuthKey(DecodedNum, true);
      console.log(ValidateStore, 'validates');
      if (ValidateStore?.storeId && ValidateStore?.storeNumber) {
        history.push('/IdentityVerify?Status=Invalid')
      }
    } else {
      history.push('/IdentityVerify?Status=Invalid')
    }
    setIsLookingUpOpen(false);
  }

  return (
    <Grid container md={12} className={`${classes.textCenter}`}>
      {IsLookingUpOpen ? <LoaderPopup message={'Please wait...'} /> : <></>}
      <RACCard style={{ height: '730px', borderRadius: 'none' }}>
      <DofHeaders />
        <Grid style={{ height: '300px', marginTop: '130px' }}>
          <Grid className={classes.headingStyles}>
            <Typography
              className={`${classes.forfont}`}
              variant="h4"
              style={{ color: '#2179FE', fontFamily: 'OpenSans-bold' }}
            >
              Welcome to RAC
            </Typography>
          </Grid>
          <Grid style={{ marginTop: '10px' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              Let's start creating your profile
            </Typography>
          </Grid>
          <Grid style={{ marginTop: '40px' }}>
            <Typography style={{ fontSize: '17px' }}>
              First we need some basic information that will include:
            </Typography>
          </Grid>

          <Grid container justifyContent="center" alignItems="center">
            <Typography style={{ marginTop: '25px' }}>

              {sessionStorageValues?.dofFeatureFlag == '1' ?
                <List>
                  <ListItem>
                    <Typography className={`${classes.forlist}`}>•</Typography>
                    <ListItemText primary="Identity Details"></ListItemText>
                  </ListItem>
                  <ListItem className={`${classes.listmargin}`}>
                    <Typography className={`${classes.forlist}`}>•</Typography>
                    <ListItemText primary="Residence"></ListItemText>
                  </ListItem>
                  <ListItem className={`${classes.listmargin}`}>
                    <Typography className={`${classes.forlist}`}>•</Typography>
                    <ListItemText primary="Income"></ListItemText>
                  </ListItem>
                  <ListItem className={`${classes.listmargin}`}>
                    <Typography className={`${classes.forlist}`}>•</Typography>
                    <ListItemText primary="Reference"></ListItemText>
                  </ListItem>
                </List> : <List>
                  <ListItem>
                    <Typography className={`${classes.forlist}`}>•</Typography>
                    <ListItemText primary="Personal Details"></ListItemText>
                  </ListItem>
                  <ListItem className={`${classes.listmargin}`}>
                    <Typography className={`${classes.forlist}`}>•</Typography>
                    <ListItemText primary="Residence"></ListItemText>
                  </ListItem>
                  <ListItem className={`${classes.listmargin}`}>
                    <Typography className={`${classes.forlist}`}>•</Typography>
                    <ListItemText primary="Employment"></ListItemText>
                  </ListItem>
                </List>}
            </Typography>
          </Grid>
          <Grid>
            <Grid>
              <Typography>
                <img src={timer} style={{ marginRight: '10px' }} />
                This process will take approximately 3 minutes to complete
              </Typography>
            </Grid>
          </Grid>
          <Grid>
            <RACButton
              onClick={() => {
                handleButton();
              }}
              style={{
                marginTop: '35px',
                width: '300px',
                backgroundColor: '#2179FE',
                color: 'white',
                textTransform: 'none',
                padding: '10px'
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                Let's start
              </Typography>
            </RACButton>
          </Grid>
        </Grid>
      </RACCard>
    </Grid>
  );
}
// export default DofStartingPage;
